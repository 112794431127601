/* eslint-disable react/display-name */
import React from "react";

export interface LabelProps
  extends React.LabelHTMLAttributes<HTMLLabelElement> {
  is_required?: boolean;
}

let Label = React.forwardRef<HTMLLabelElement, LabelProps>(
  ({ is_required, ...props }, ref) => {
    return (
      <div>
        <label
          className="block text-sm font-medium leading-6 text-black dark:text-white"
          ref={ref}
          {...props}
        >
          {props.children} {is_required && "*"}
        </label>
      </div>
    );
  }
);

export { Label };

export let UpperLabel = React.forwardRef<HTMLLabelElement, LabelProps>(
  ({ is_required, ...props }, ref) => {
    return (
      <div>
        <label
          className="block uppercase tracking-wide text-gray-300 text-xs font-bold mb-2"
          ref={ref}
          {...props}
        >
          {props.children} {is_required && "*"}
        </label>
      </div>
    );
  }
);

export let LabelOL = React.forwardRef<HTMLLabelElement, LabelProps>(
  ({ is_required, ...props }, ref) => {
    return (
      <div>
        <label
          className="flex text-sm font-medium leading-6 text-black dark:text-white justify-end justify-items-end mt-2 mr-4"
          ref={ref}
          {...props}
        >
          {props.children} {is_required && "*"}
        </label>
      </div>
    );
  }
);

// has correct size to be used next to input without any adjustment
export let LabelOLnew = React.forwardRef<HTMLLabelElement, LabelProps>(
  ({ is_required, ...props }, ref) => {
    return (
      <div className="relative mt-1 mb-3">
        <label
          className="flex text-sm font-medium leading-6 text-black dark:text-white py-1.5 whitespace-nowrap mr-4"
          ref={ref}
          {...props}
        >
          {props.children} {is_required && "*"}
        </label>
      </div>
    );
  }
);
