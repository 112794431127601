import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { BellIcon } from "@heroicons/react/24/outline";
import { type login_notification } from "@prisma/client";
import { Form } from "react-router";
import { Fragment } from "react/jsx-runtime";
import { NotificationType } from "~/constants/prismaEnums";
import { SUBMIT_INTENT } from "~/constants/routeConstants";

interface NotificationBellProps extends React.ComponentPropsWithRef<"button"> {
  children?: React.ReactNode;
  isLoading?: boolean;
  number?: number;
  notifications: login_notification[];
}

export const INTENT_SETREAD = "setread";
export const FORMDATA_NOTIFICATIONID = "notificationid";

let NotificationBell: React.FC<NotificationBellProps> = ({
  children,
  isLoading,
  number,
  notifications,
  ...props
}) => {
  let isEnabled = props.disabled !== true;
  let isHover = isEnabled && !isLoading;

  return (
    <Popover className="relative">
      {(panel) => {
        let { open, close } = panel;
        return (
          <>
            <div className="relative inline-flex w-fit">
              <div className="absolute bottom-auto left-auto right-0 top-0 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap rounded-full bg-button-light dark:bg-button-dark px-2.5 py-1 text-center align-baseline text-xs font-bold leading-none text-black dark:text-white">
                {number}
              </div>
              <PopoverButton
                onClick={() => {
                  if (!open) {
                    // On the first click, the panel is opened but the "open" prop's value is still false. Therefore the falsey verification
                    // This will make so the panel close itself when we click it while open
                    close();
                  }
                }}
                className={`rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline 
  focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-link-light focus-visible:dark:outline-link-dark
  flex items-center justify-center 
  ${isEnabled && " bg-transparent text-black dark:text-white "}
${isHover && " hover:bg-link-light hover:dark:bg-link-dark "}
${
  !isEnabled &&
  !isLoading &&
  "bg-gray-light dark:bg-gray-dark text-gray-500 cursor-not-allowed "
}
  `}
                {...props}
              >
                {isLoading && (
                  <svg
                    className="animate-spin h-5 w-5 mr-3 ..."
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                <BellIcon
                  className="h-6 w-6 shrink-0 text-black dark:text-white"
                  aria-hidden="true"
                />
              </PopoverButton>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverPanel className="absolute left-1/2 z-50 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                <div className="w-screen max-w-sm flex-auto rounded-3xl bg-bg-light dark:bg-bg-dark border-gray-light dark:border-gray-dark border-2 p-4 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                  {notifications &&
                    notifications.length > 0 &&
                    notifications?.map((notification) => (
                      <div
                        key={notification.id}
                        className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-button-light hover:dark:bg-button-dark"
                      >
                        <div>
                          <div>
                            <Form method="post">
                              <input
                                type="hidden"
                                name={FORMDATA_NOTIFICATIONID}
                                value={notification && notification.id}
                                key={notification && notification.id}
                              />
                              <button
                                type="submit"
                                name={SUBMIT_INTENT}
                                value={INTENT_SETREAD}
                                className="font-semibold text-sm text-text-light dark:text-text-dark"
                                onClick={() => {
                                  if (!open) {
                                    // On the first click, the panel is opened but the "open" prop's value is still false. Therefore the falsey verification
                                    // This will make so the panel close itself when we click it while open
                                    close();
                                  } else {
                                    close();
                                  }
                                }}
                              >
                                {notification.notification_type ===
                                NotificationType.default
                                  ? null
                                  : notification.notification_type}
                                <span className="absolute inset-0" />
                              </button>
                              {notification && !notification.is_read && (
                                <p className="mt-1 text-text-light dark:text-text-dark">
                                  {notification.message}
                                </p>
                              )}
                              {notification && notification.is_read && (
                                <p className="mt-1 text-text-light dark:text-text-dark">
                                  {notification.message}
                                </p>
                              )}{" "}
                            </Form>
                          </div>
                        </div>
                      </div>
                    ))}
                  {notifications && notifications.length === 0 && (
                    <div>
                      <p className="mt-1 text-text-light dark:text-text-dark">
                        {" "}
                        No new unread notifications at the moment!
                      </p>
                    </div>
                  )}
                </div>
              </PopoverPanel>
            </Transition>
          </>
        );
      }}
    </Popover>
  );
};

export default NotificationBell;
