/* v8 ignore next 100 */

import { useEffect } from "react";
import { useLoaderData, useResolvedPath, useRevalidator } from "react-router";

import { useEventSource } from "remix-utils/sse/react";

export function useLiveLoader<T>() {
  let path = useResolvedPath("./subscribenotificationstream");
  let data = useEventSource(path.pathname, {
    event: "notification",
  });
  let { revalidate } = useRevalidator();

  useEffect(() => {
    if (data) {
      revalidate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return useLoaderData<T>();
}
