import { PLACEHOLDER } from "~/constants/constants";

export default {
  completedSuccess: "Aufgabe erfolgreich abgeschlossen!",
  errorOnCompletion: "Fehler beim Abschließen der Aufgabe",
  errorOnLoadingTasks: "Fehler beim Laden der Aufgaben",
  nearlyExpiredUTP: "Ablaufende Angebote",
  nearlyExpiredUTPBidderText:
    "Ein Angebot von Ihnen läuft in den nächsten Tagen ab.",
  nearlyExpiredUTPsBidderText: `${PLACEHOLDER} Angebote von Ihnen laufen in den nächsten Tagen ab.`,
  nearlyExpiredUTPCreatorText:
    "Ein Angebot für Sie läuft in den nächsten Tagen ab.",
  nearlyExpiredUTPsCreatorText: `${PLACEHOLDER} Angebote für Sie laufen in den nächsten Tagen ab.`,
  deliveryDecision: "Lieferung wählen",
  deliveryDecisionText:
    "Bei einem Deal müssen Sie noch eine Lieferart auswählen.",
  deliveryDecisionNumberText: `Bei ${PLACEHOLDER} Deals müssen Sie noch eine Lieferart auswählen.`,
  utpActionNeeded: "Angebote für Ihre Anfragen",
  utpActionNeededText: "Ein Angebot wartet auf Ihre Antwort.",
  utpsActionNeededText: `${PLACEHOLDER} Angebote warten auf Ihre Antworten.`,
  myUtpActionNeeded: "Antworten auf Ihre Angebote",
  myUtpActionNeededText:
    "Es gibt derzeit eine Antwort auf eines Ihrer Angebote, auf das Sie reagieren müssen.",
  myUtpsActionNeededText: `Es gibt derzeit ${PLACEHOLDER} Antworten auf Ihre Angebote, auf die Sie reagieren müssen.`,
};
