import { DateTimeFormatter, ZonedDateTime, nativeJs } from "@js-joda/core";
import type { Prisma, tradeproduct } from "@prisma/client";
import { REGEX_GUID } from "app/constants/regex";
import { stringToNumber } from "./stringUtils";

export function hasWhiteSpace(s: string) {
  return s.indexOf(" ") >= 0;
}

export function printFormData(formData: FormData) {
  for (let pair of formData.entries()) {
    console.log(`${pair[0]}: ${pair[1]}`);
  }
}

// can be used in catch blocks to ensure if a thrown object is an error. If not, is it wrapped as an error for further handling.
// https://medium.com/with-orus/the-5-commandments-of-clean-error-handling-in-typescript-93a9cbdf1af5
export function ensureError(value: unknown): Error {
  if (value instanceof Error) return value;

  let stringified = "[Unable to stringify the thrown value]";
  try {
    stringified = JSON.stringify(value);
    /* v8 ignore next 3 */
  } catch {}

  let error = new Error(
    `This value was thrown as is, not through an Error: ${stringified}`
  );
  return error;
}

// Result type for functions with expected errors
// https://medium.com/with-orus/the-5-commandments-of-clean-error-handling-in-typescript-93a9cbdf1af5
export type Result<T, E extends Error = Error> =
  | { success: true; result: T }
  | { success: false; error: E };

// converts a base64 image to a blob
export function base64ImgToBlob(base64String: string): Result<Blob> {
  try {
    let base64FormatRegex = /^data:image\/(?:png|jpeg|jpg|gif);base64,/;

    if (!base64FormatRegex.test(base64String)) {
      throw new Error("Invalid base64 format");
    }

    let byteString = atob(base64String.split(",")[1]);
    let mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    let result = new Blob([ab], { type: mimeString });

    return {
      success: true,
      result,
    };
  } catch (e) {
    let error = ensureError(e);
    return { success: false, error };
  }
}

/**
 * Ensures the value is a valid GUID
 * @param value string value
 */
export function isValidGUID(value: string): boolean {
  return REGEX_GUID.test(value);
}

export function convertPrismaDecimalToNumber(
  decimalValue: Prisma.Decimal
): number {
  /* v8 ignore next 3 */
  if (typeof decimalValue === "string") {
    return decimalValue;
  }
  //  handle prisma.Decimals better?
  // values can look like this:
  // 123,45:
  // d: [123, 45]
  // s: 1
  // e: 2
  // 10.000.000
  // d: [1]
  // s: 1
  // e: 7
  let sign: number = decimalValue.s;
  let exp: number = decimalValue.e;
  // intVal: multiplicate with 10^exp, but only if exp is 7 or higher: https://mikemcl.github.io/decimal.js/#instance-properties
  // "The value of a Decimal is stored in a normalised base 10.000.000 floating point format."
  let intVal: number = decimalValue.d[0] * (exp >= 7 ? 10 ** exp : 1); //
  let fractInt: number = decimalValue.d.length > 1 ? decimalValue.d[1] : 0;
  let fractVal: number =
    fractInt === 0 ? 0 : fractInt / 10 ** fractInt.toString().length;
  let retVal = sign * (intVal + fractVal); // * 10 ** exp;
  return retVal;
}

export function printNumber(
  val: Prisma.Decimal | number | null,
  decimals: number,
  currency?: string | undefined
) {
  //  WTF i hass Prisma.Decimal
  // aus tradeproductshop, mit normalem defer und useLoader und Await, wäre product.Price ein string. Da kommt aber im Client dann immer ein Fehler, dass Prisma.Decimal kein React-Child ist.
  // Uncaught Error: Objects are not valid as a React child (found: [object Decimal]). If you meant to render a collection of children, use an array instead.
  // mit typeddefer, useTypedLoader und TypedAwait, ist der Typ laut VSCode immer Prisma.Decimal. Das fliegt mir aber hier teilweise mit
  // tools.tsx:126 Uncaught TypeError: Cannot read properties of undefined (reading '0')
  //  at convertPrismaDecimalToNumber (tools.tsx:126:39)
  //  at printAsEUR
  // also hab ich den Typ geprüft und das ist ein string. Also check ich jetzt hier auf string.
  // den Originalfehler sieht man noch in der Trade Overview. Aber nur beim Refresh, nicht beim normalen Navigieren!

  if (val === null) {
    return "";
  }

  let number: number;
  /* v8 ignore next 3 */
  if (typeof val === "string") {
    let tryParse = stringToNumber(val);
    if (tryParse) {
      number = tryParse;
    } else {
      return val;
    }
  }
  try {
    if (typeof val === "number") {
      number = val;
    } else {
      number = convertPrismaDecimalToNumber(val);
    }
    let formatter = new Intl.NumberFormat("de-DE", {
      style: currency ? "currency" : undefined,
      currency: currency,
      minimumFractionDigits: decimals,
      useGrouping: true, // Use grouping (comma separator)
    });

    return formatter.format(number);
    /* v8 ignore next 3 */
  } catch (e) {
    throw e;
  }
}

export function printAsEUR(val: Prisma.Decimal | number | null): string {
  return printNumber(val, 2, "EUR");
}

export function printSafeDateString(val: Date | null): string {
  if (val === null) {
    return "";
  }

  /* v8 ignore next 6 */
  if (typeof val === "string") {
    let dateString = ZonedDateTime.parse(val).format(
      DateTimeFormatter.ofPattern("dd.MM.yyyy")
    );
    return dateString;
  }
  try {
    let DateUTCString = nativeJs(val).format(
      DateTimeFormatter.ofPattern("dd.MM.yyyy")
    );
    return DateUTCString;
    /* v8 ignore next 3 */
  } catch (e) {
    throw e;
  }
}

export function printSafeDateStringRange(dateFrom: Date, dateTo: Date): string {
  let fromString = printSafeDateString(dateFrom);
  let toString = printSafeDateString(dateTo);
  if (fromString === toString) {
    return fromString;
  } else {
    return `${fromString} - ${toString}`;
  }
}

export function getDeliveryDates(product: tradeproduct): string {
  let deliverDateString = "";

  //TODO übersetzung mit sabrina hier anschauen, kann man den hook einfach usen? restriction of hooks - hooks inside hooks etc.

  let deliveryDate_from = printSafeDateString(product.delivery_period_from);
  let deliveryDate_to = printSafeDateString(product.delivery_period_to);
  if (deliveryDate_from && deliveryDate_to) {
    if (deliveryDate_from !== deliveryDate_to) {
      deliverDateString = deliveryDate_from + " - " + deliveryDate_to;
    } else if (deliveryDate_from === deliveryDate_to) {
      deliverDateString = deliveryDate_from;
    }
  } else if (deliveryDate_from) {
    deliverDateString = "ab " + deliveryDate_from;
  } else if (deliveryDate_to) {
    deliverDateString = "bis " + deliveryDate_to;
  }

  return deliverDateString;
}

export function hasProperty(object: any, propertyName: string): boolean {
  return (
    typeof object === "object" && object !== null && propertyName in object
  );
}
