import type { FieldName } from "@conform-to/react";
import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import type { ComboBoxSimpleDefinitionType } from "../Comboboxes/ComboBox";

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(" ");
}

type ComboBoxOLProps = {
  comboBoxData: Array<ComboBoxSimpleDefinitionType>;
  assignedToLabel: string;
  name: FieldName<string>;
  labelClassName?: string;
  onChangeCustom?: (selectedOption: ComboBoxSimpleDefinitionType) => void;
  value?: string | undefined | null;
};

// Combo Box One Line
let ComboBoxOL = ({
  name,
  comboBoxData,
  assignedToLabel: assingedToLabel,
  labelClassName,
  onChangeCustom,
  value,
}: ComboBoxOLProps): JSX.Element => {
  let [query, setQuery] = useState("");

  let { t } = useTranslation();
  let filteredData =
    query === ""
      ? comboBoxData
      : comboBoxData.filter((comboBoxData) => {
          return comboBoxData.value.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox
      as="div"
      className="flex items-center gap-x-4"
      value={value}
      onChange={(selectedValue) => {
        let selectedOption = comboBoxData.find(
          (option) => option.value === selectedValue
        );
        if (selectedOption) {
          onChangeCustom && onChangeCustom(selectedOption);
        }
      }}
    >
      <div className="relative mt-2">
        <Combobox.Label
          className={`${
            labelClassName ??
            "block text-sm font-medium leading-6 text-black dark:text-white"
          }`}
        >
          {assingedToLabel}

          <Combobox.Input
            className="w-full rounded-md border-0 bg-bg-light py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={(event) => {
              setQuery(event.target.value);
            }}
          />
          <Combobox.Button className="absolute mt-6 inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        </Combobox.Label>
        {filteredData.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-bg-light py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredData.map((option) => (
              <Combobox.Option
                key={option.key}
                value={option.value}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active
                      ? "bg-indigo-600 text-black dark:text-white"
                      : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {t(option.value)}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active
                            ? "text-black dark:text-white"
                            : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default ComboBoxOL;
