import { Link } from "react-router";
import logo from "app/assets/logo.png";
import logoWhiteMode from "app/assets/neon-logo_black.png";
import type { Theme } from "~/utils/theme.server";

interface LogoProps extends React.ComponentPropsWithoutRef<"img"> {
  height?: number;
  theme: Theme | null;
  isOwnPlacing?: boolean;
  isLink?: boolean;
}

export let Logo: React.FC<LogoProps> = ({
  height,
  theme,
  isOwnPlacing,
  isLink = true,
}) => {
  let autoPlacing = "w-auto mx-auto";
  if (isOwnPlacing) {
    autoPlacing = "";
  }
  if (!height) {
    height = 10;
  }
  return isLink ? (
    <Link to="/">{logoFunction(height, theme, autoPlacing)}</Link>
  ) : (
    <>{logoFunction(height, theme, autoPlacing)}</>
  );
};

function logoFunction(
  height: number,
  theme: Theme | null,
  autoPlacing: string
) {
  return (
    <>
      {(theme === "dark" || !theme) && (
        <div className="relative group">
          <img
            src={logo}
            className={`h-${height} ${autoPlacing} absolute inset-0 blur 
            opacity-0 group-hover:opacity-100 transition duration-1000 group-hover:duration-200`}
            alt="Connect8"
          />
          <img
            src={logo}
            className={`h-${height} ${autoPlacing} relative leading-none flex items-center divide-x divide-gray-600`}
            alt="Connect8"
          />
        </div>
      )}
      {theme === "light" && (
        <div className="relative group">
          <img
            src={logoWhiteMode}
            className={`h-${height} ${autoPlacing} absolute inset-0 blur 
            opacity-0 group-hover:opacity-100 transition duration-1000 group-hover:duration-200`}
            alt="Connect8"
          />
          <img
            src={logoWhiteMode}
            className={`h-${height} ${autoPlacing} relative leading-none flex items-center divide-x divide-gray-600`}
            alt="Connect8"
          />
        </div>
      )}
    </>
  );
}

<img className="mx-auto h-10 w-auto" src={logo} alt="Connect8" />;
