export function getTodaysDate(dayManipulator?: number): Date {
  let currentDateTime = new Date();
  if (dayManipulator) {
    currentDateTime.setDate(currentDateTime.getDate() + dayManipulator);
  }
  currentDateTime.setHours(0, 0, 0, 0);
  return currentDateTime;
}

export function getTomorrowsDate(): Date {
  let tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  tomorrow.setHours(0, 0, 0, 0);
  return tomorrow;
}

export function getDateWithoutTime(dateTime: Date): Date {
  if (dateTime) {
    dateTime.setHours(0, 0, 0, 0);
  }
  return dateTime;
}

export function parseDateString(dateString: string): Date | null {
  const [day, month, year] = dateString
    .split(".")
    .map((part) => parseInt(part, 10));
  if (!day || !month || !year) return null;
  // JavaScript Date constructor takes month index from 0 (January is 0, December is 11)
  return new Date(year, month - 1, day);
}

export function formatDateForInput(date: Date | null | undefined) {
  if (date) {
    return date.toISOString().slice(0, 10);
  }
  return "";
}

export function addDays(date: Date, days: number) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
