import type { ListOfErrors } from "./Form";

export function requiredErrorLogic(errors: ListOfErrors, id: string) {
  let tempErrorList = errors?.slice();
  let requiredErrorIndex = tempErrorList?.findIndex(
    (x) => x === "zod:requiredField"
  );
  let isRequiredError = false;
  if (
    requiredErrorIndex !== undefined &&
    requiredErrorIndex !== null &&
    requiredErrorIndex > -1
  ) {
    tempErrorList?.splice(requiredErrorIndex, 1);
    isRequiredError = true;
  }
  let errorId =
    tempErrorList?.length && tempErrorList.length > 0
      ? `${id}-error`
      : undefined;

  return { tempErrorList, isRequiredError, errorId };
}
