import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import {
  ArchiveBoxIcon,
  ArrowRightIcon,
  ArrowRightStartOnRectangleIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  Cog6ToothIcon,
  FolderIcon,
  GlobeAltIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  PlusCircleIcon,
  PresentationChartBarIcon,
  QuestionMarkCircleIcon,
  QueueListIcon,
  ServerIcon,
  ShoppingCartIcon,
  TruckIcon,
  WrenchIcon,
  WrenchScrewdriverIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import type { user_shop_filter } from "@prisma/client";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Link, useLocation } from "react-router";
import { CompanyAddressType, LoginType } from "~/constants/prismaEnums";
import {
  ROUTE_ADMIN_INVOICES,
  ROUTE_ADMIN_LOGINS,
  ROUTE_ADMIN_NEWEMPLOYEE,
  ROUTE_ADMIN_REDIS,
  ROUTE_ADMIN_TRADEPRODUCT,
  ROUTE_COMPANYSETTINGS,
  ROUTE_LOGISTIC,
  ROUTE_LOGISTICOVERVIEW_DONE,
  ROUTE_LOGISTICOVERVIEW_MYOFFERS,
  ROUTE_LOGISTICOVERVIEW_MYREQUESTS,
  ROUTE_LOGISTICOVERVIEW_PAST,
  ROUTE_TRADEOVERVIEW_MYOFFERS,
  ROUTE_TRADEOVERVIEW_MYOFFERS_DELIVERY,
  ROUTE_TRADEOVERVIEW_MYREQUESTS,
  ROUTE_TRADEPRODUCT_CREATE,
  ROUTE_TRADEPRODUCTSHOP,
  SUBMIT_INTENT,
} from "~/constants/routeConstants";
import type { CompanyExtended, LoginExtended } from "~/types/CombinedTypes";
import { classNames } from "~/utils/misc";
import type { Theme } from "~/utils/theme.server";
import { Logo } from "./Logo";
import { Icon } from "./icon";

interface Navigation {
  name: string;
  href: string;
  // TODO: remove later, when all icons are svg icons which are built
  // for now, use both variants parallel
  icon?: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & React.RefAttributes<SVGSVGElement>
  >;
  iconNew?: JSX.Element;
  children?: Navigation[] | undefined;
}

export const INTENT_DELETEQUICKFILTER = "deleteQuickFilter";
export const FORMDATA_QUICKFILTERID = "quickfilterid";
export const FORMDATA_LOGINID = "loginid";

const employeeNavigation: Navigation[] = [
  {
    name: "tasks",
    href: "/internal/tasklist",
    icon: FolderIcon,
    children: undefined,
  },
  {
    name: "admin",
    href: "",
    icon: WrenchIcon,
    children: [
      {
        name: "newemployee",
        icon: ArrowRightIcon,
        href: ROUTE_ADMIN_NEWEMPLOYEE,
      },
      {
        name: "accounts",
        icon: ArrowRightIcon,
        href: ROUTE_ADMIN_LOGINS,
      },
      {
        name: "redis",
        icon: ArrowRightIcon,
        href: ROUTE_ADMIN_REDIS,
      },
      {
        name: "Invoices",
        icon: ArrowRightIcon,
        href: ROUTE_ADMIN_INVOICES,
      },
      {
        name: "Tradeproduct",
        icon: ArrowRightIcon,
        href: ROUTE_ADMIN_TRADEPRODUCT,
      },
    ],
  },
  {
    name: "logout",
    href: "/logout",
    icon: ArrowRightStartOnRectangleIcon,
    children: undefined,
  },
];

interface SidebarProps {
  login: LoginExtended;
  loginName: string;
  defaultFilters: user_shop_filter[] | undefined;
  theme: Theme | null;
  company: CompanyExtended | null;
}

export default function Sidebar({
  login,
  loginName,
  defaultFilters,
  theme,
  company,
}: SidebarProps) {
  let location = useLocation();

  let navigation: Navigation[] = [];

  if (login?.login_type) {
    switch (login.login_type) {
      case LoginType.InternalEmployee: {
        navigation = employeeNavigation;
        break;
      }
      case LoginType.User: {
        if (company) {
          if (company.is_buyer) {
            //TODOSL
            navigation.push({
              name: "newProductRequest",
              href: ROUTE_TRADEPRODUCT_CREATE,
              icon: PlusCircleIcon,
              children: undefined,
            });
          }
          if (company.is_seller) {
            //TODOSL
          }
          if (company.is_carrier) {
            //TODOSL
          }
          if (company.is_buyer || company?.is_seller) {
            navigation.push({
              name: "connect8trading",
              href: "",
              icon: ShoppingCartIcon,
              children: [
                {
                  name: "openProductRequests",
                  icon: MagnifyingGlassIcon,
                  href: ROUTE_TRADEPRODUCTSHOP,
                },
                {
                  name: "tradeMyRequests",
                  icon: QueueListIcon,
                  href: ROUTE_TRADEOVERVIEW_MYREQUESTS,
                },
                {
                  name: "tradeMyOffers",
                  icon: QueueListIcon,
                  href: ROUTE_TRADEOVERVIEW_MYOFFERS,
                },
                {
                  name: "tradeDelivery",
                  icon: TruckIcon,
                  href: ROUTE_TRADEOVERVIEW_MYOFFERS_DELIVERY,
                },
              ],
            });
          }
          if (company.is_seller || company.is_carrier) {
            navigation.push({
              name: "connect8Logistic",
              href: "/connect8logistic",
              icon: GlobeAltIcon,
              children: [
                {
                  name: "openLogisticRequests",
                  icon: MagnifyingGlassIcon,
                  href: ROUTE_LOGISTIC,
                },
                {
                  name: "myLogisticRequests",
                  icon: QueueListIcon,
                  href: `${ROUTE_LOGISTICOVERVIEW_MYREQUESTS}`,
                },
                {
                  name: "myLogisticOffers",
                  icon: QueueListIcon,
                  href: `${ROUTE_LOGISTICOVERVIEW_MYOFFERS}`,
                },
                {
                  name: "doneLogistics",
                  icon: CheckCircleIcon,
                  href: `${ROUTE_LOGISTICOVERVIEW_DONE}`,
                },
                {
                  name: "pastLogistics",
                  icon: ArchiveBoxIcon,
                  href: `${ROUTE_LOGISTICOVERVIEW_PAST}`,
                },
              ],
            });
          }
        }
        navigation.push(
          {
            name: "addressManagement",
            href: "",
            icon: HomeIcon,
            children: [
              {
                name: "deliveryAddresses",
                icon: ArrowRightIcon,
                href: `addressmanagement/${CompanyAddressType.Delivery}`,
              },
              {
                name: "billingAddresses",
                icon: ArrowRightIcon,
                href: `addressmanagement/${CompanyAddressType.Billing}`,
              },
              {
                name: "loadingAddresses",
                icon: ArrowRightIcon,
                href: `addressmanagement/${CompanyAddressType.Loading}`,
              },
              {
                name: "agreedplaceAddresses",
                icon: ArrowRightIcon,
                href: `addressmanagement/${CompanyAddressType.AgreedPlace}`,
              },
            ],
          },
          {
            name: "faq",
            href: "/generalfaq",
            icon: QuestionMarkCircleIcon,
            children: undefined,
          }
        );
        if (company && company.adminMails.includes(login.email)) {
          navigation.push({
            name: "companyAdmin",
            href: "",
            icon: Cog6ToothIcon,
            children: [
              {
                name: "registerUser",
                href: "/registeruser",
                icon: ServerIcon,
              },
              {
                name: "salesdashboard",
                icon: PresentationChartBarIcon,
                href: "salesdashboard",
              },
              {
                name: "invoicesoverview",
                iconNew: <Icon size="lg" name="document-currency-euro" />,
                href: "invoiceoverview/Actual",
              },
              {
                name: "companySettings",
                icon: WrenchScrewdriverIcon,
                href: ROUTE_COMPANYSETTINGS,
              },
            ],
          });
        }
        navigation.push({
          name: "logout",
          href: "/logout",
          icon: ArrowRightStartOnRectangleIcon,
          children: undefined,
        });
        break;
      }
    }
  }

  let { t } = useTranslation();

  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>(
    undefined
  ); // Speichert den Index des aktuell ausgewählten Elements
  let timer: string | number | NodeJS.Timeout | undefined;

  let duration = 2000;

  const handleMouseEnter = (index: number) => {
    // Timer starten, um Tooltip nach 2 Sekunden anzuzeigen
    timer = setTimeout(() => {
      setHoveredIndex(index);
    }, duration);
  };

  const handleMouseLeave = () => {
    // Timer abbrechen und Tooltip ausblenden
    clearTimeout(timer);
    setHoveredIndex(undefined);
  };

  useEffect(() => {
    // Cleanup für den Timer, falls das Element entfernt wird
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-black/10 px-6 ring-1 ring-white/5">
          <div className="flex pt-16 h-16 shrink-0 items-center justify-center justify-items-center">
            <Logo theme={theme} />
          </div>
          <nav className="flex flex-1 flex-col mt-11">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      {!item.children ? (
                        <Link
                          to={item.href}
                          className={classNames(
                            location.pathname === item.href
                              ? "text-button-light dark:text-button-dark"
                              : "text-gray-light dark:text-gray-dark hover:text-button-light hover:dark:text-button-dark",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          {item.icon && (
                            <item.icon
                              className={classNames(
                                "h-6 w-6 shrink-0",
                                location.pathname === item.href
                                  ? "text-button-light dark:text-button-dark"
                                  : ""
                              )}
                              aria-hidden="true"
                            />
                          )}
                          {item.iconNew && item.iconNew}
                          {t(item.name)}
                        </Link>
                      ) : (
                        <Disclosure as="div">
                          {({ open }) => (
                            <>
                              <DisclosureButton
                                className={classNames(
                                  location.pathname === item.href ||
                                    item.children?.some(
                                      (c) => c.href === location.pathname
                                    )
                                    ? "text-button-light dark:text-button-dark"
                                    : "text-gray-light dark:text-gray-dark hover:text-button-light hover:dark:text-button-dark",
                                  "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold"
                                )}
                              >
                                {item.icon && (
                                  <item.icon
                                    className={classNames(
                                      "h-6 w-6 shrink-0",
                                      location.pathname === item.href ||
                                        item.children?.some(
                                          (c) => c.href === location.pathname
                                        )
                                        ? "text-button-light dark:text-button-dark"
                                        : ""
                                    )}
                                    aria-hidden="true"
                                  />
                                )}
                                {item.iconNew && item.iconNew}
                                {t(item.name)}
                                <ChevronRightIcon
                                  className={classNames(
                                    open
                                      ? "rotate-90 text-gray-500"
                                      : "text-gray-400",
                                    "ml-auto h-5 w-5 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                              </DisclosureButton>
                              <DisclosurePanel as="ul" className="mt-1 px-2">
                                {item.children &&
                                  item.children.map((subItem) => (
                                    <li key={subItem.name}>
                                      <Link
                                        to={subItem.href}
                                        className={classNames(
                                          location.pathname === subItem.href
                                            ? "text-link-light dark:text-link-dark"
                                            : "text-gray-light dark:text-gray-dark hover:text-link-light hover:dark:text-link-dark",
                                          "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold"
                                        )}
                                      >
                                        {subItem.icon && (
                                          <subItem.icon className="h-5 w-5 shrink-0" />
                                        )}
                                        {subItem.iconNew && subItem.iconNew}
                                        {t(subItem.name)}
                                      </Link>
                                    </li>
                                  ))}
                              </DisclosurePanel>
                            </>
                          )}
                        </Disclosure>
                      )}
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <div className="text-xs font-semibold leading-6 text-gray-light dark:text-gray-dark">
                  {t("yourQuickfilters")}
                </div>
                <ul className="-mx-2 mt-2 space-y-1">
                  {defaultFilters?.map((defaultFilter, index) => (
                    <li key={defaultFilter.id}>
                      <Form method="POST" navigate={false}>
                        <div
                          className="relative group flex max-w-full cursor-pointer"
                          onMouseEnter={() => handleMouseEnter(index)} // Spezifischer Index für jedes Element
                          onMouseLeave={handleMouseLeave}
                        >
                          <input
                            type="hidden"
                            name={FORMDATA_QUICKFILTERID}
                            value={defaultFilter.id}
                            key={defaultFilter.id}
                          />
                          <input
                            type="hidden"
                            name={FORMDATA_LOGINID}
                            value={login.id}
                            key={login.id}
                          />
                          <Link
                            to={`.${ROUTE_TRADEPRODUCTSHOP}?${defaultFilter.search_param_string}`}
                            className={classNames(
                              defaultFilter.title
                                ? "bg-gray-800 text-black dark:text-white"
                                : "text-gray-light dark:text-gray-dark hover:text-black hover:dark:text-white hover:bg-gray-800",
                              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-10/12"
                            )}
                          >
                            <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-black dark:text-white">
                              {"F"}
                            </span>
                            <span className="truncate">
                              {defaultFilter.title}
                            </span>
                          </Link>
                          <button
                            type="submit"
                            name={SUBMIT_INTENT}
                            value={INTENT_DELETEQUICKFILTER}
                            disabled={hoveredIndex !== index}
                          >
                            <XCircleIcon
                              className={`h-6 w-6 shrink-0 ease-in transition-colors duration-[${duration}ms] group-hover:text-red-500 text-gray-light dark:group-hover:text-red-500 dark:text-gray-dark ml-2`}
                              aria-hidden="true"
                            />
                          </button>
                          {hoveredIndex === index && (
                            <div className="absolute bottom-12 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-sm px-3 py-2 rounded opacity-100 transition-opacity duration-300">
                              {defaultFilter.title}
                            </div>
                          )}
                        </div>
                      </Form>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="-mx-6 mt-auto">
                <a
                  href="/settings"
                  className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-black dark:text-white hover:bg-button-light hover:dark:bg-button-dark"
                >
                  {login.profilePicture && (
                    <img
                      className="h-8 w-8 rounded-full bg-gray-800"
                      src={login.profilePicture}
                      alt=""
                    />
                  )}
                  <span className="sr-only">{t("yourProfile")}</span>
                  <span aria-hidden="true">{loginName}</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}
