export enum TradeOverviewProductStateType {
  Active = "active",
  Rejected = "rejected",
  Expired = "expired",
  // Delivery = "delivery", // not in this enum, as it is a separate route!
  Done = "done",
}
export function isTradeOverviewProductStateType(
  value: string
): value is TradeOverviewProductStateType {
  return Object.values(TradeOverviewProductStateType).includes(
    value as TradeOverviewProductStateType
  );
}
