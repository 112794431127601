import * as React from "react";
import { classNames } from "~/utils/misc";

export interface CheckboxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "checked"> {
  name: string;
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ name, ...props }, ref) => {
    return (
      <input
        ref={ref}
        className={classNames(
          `mt-1 rounded-md text-button-light dark:text-button-dark`
        )}
        {...props}
        type="checkbox"
        name={name}
        defaultChecked={props.defaultChecked}
        onChange={(e) => {
          e.target.defaultChecked =
            props.defaultChecked === true ? true : false;
        }}
      />
    );
  }
);

Checkbox.displayName = "Checkbox";

export { Checkbox };
