export type NumberRange = {
  min: number | undefined;
  max: number | undefined;
};

export function isMinMaxType(value: any): value is NumberRange {
  return (
    typeof value === "object" &&
    value !== null &&
    "min" in value &&
    "max" in value
  );
}
