import { CalendarDaysIcon } from "@heroicons/react/20/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import type { ForwardedRef } from "react";
import React, { useRef, useState } from "react";
import { getTodaysDate } from "~/utils/dateTools";
import { printSafeDateString } from "~/utils/tools";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: any;
  iconRight?: any;
  description?: string;
  isRequiredError: boolean;
}

let Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { type, icon, iconRight, isRequiredError, ...props },
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <div className="w-full">
        <div className="relative mt-1 mb-3 rounded-md shadow-sm">
          {icon && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              {icon}
            </div>
          )}
          {type === "date" ? (
            <DateInput
              hasIcon={icon}
              {...props}
              isRequiredError={isRequiredError}
            />
          ) : (
            <input
              type={type}
              ref={ref}
              {...props}
              className={`bg-bg-light dark:bg-bg-dark block w-full rounded-md border-0 py-1.5 ${
                icon ? "pl-10" : ""
              } text-black dark:text-white ${
                isRequiredError
                  ? "ring-2 shadow-lg shadow-red-600/50 ring-red-600 dark:ring-red-600"
                  : "ring-1 ring-button-light dark:ring-button-dark "
              } ring-inset focus:ring-link-light focus:dark:ring-link-dark 
              placeholder:text-gray-placeholder focus:ring-2 focus:ring-inset 
              sm:text-sm sm:leading-6 read-only:text-gray-400 read-only:ring-gray-500
              dark:read-only:text-gray-400 dark:read-only:ring-gray-500 dark:[color-scheme:dark]`}
            />
          )}
          {iconRight && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              {iconRight}
            </div>
          )}
        </div>
      </div>
    );
  }
);

Input.displayName = "Input";

interface DateInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  hasIcon: boolean;
  isRequiredError: boolean;
}
let DateInput = React.forwardRef<HTMLInputElement, DateInputProps>(
  (
    { hasIcon, isRequiredError, ...props },
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    let [formattedDate, setFormattedDate] = useState(""); // For dd.MM.yyyy display
    let hiddenDateRef = useRef<HTMLInputElement>(null); // Ref for hidden date input

    let formatDateToDisplay = (dateValue: string) => {
      if (!dateValue) return ""; // Handle empty value
      let [year, month, day] = dateValue.split("-");
      return `${day}.${month}.${year}`;
    };

    let formatDateToISO = (dateValue: string) => {
      let parts = dateValue.split(".");
      if (parts.length === 3) {
        let [day, month, year] = parts;
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
      return "";
    };

    let handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value.replace(/[^0-9.]/g, ""); // Allow only numbers and dots
      if (value.length === 2 || value.length === 5) {
        value += ".";
      }
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
      setFormattedDate(value);
      let isoDate = formatDateToISO(value);
      if (hiddenDateRef.current) {
        hiddenDateRef.current.value = isoDate; // Update hidden input value
      }
    };

    let handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value; // ISO date from hidden input
      setFormattedDate(formatDateToDisplay(value)); // Update visible input
    };

    let today = getTodaysDate();

    return (
      <div className="relative">
        {/* Visible text input */}
        <input
          type="text"
          value={formattedDate}
          onChange={handleTextChange}
          placeholder={printSafeDateString(today)}
          className={`bg-bg-light dark:bg-bg-dark block w-full rounded-md border-0 py-1.5 pr-10 ${
            hasIcon ? "pl-10" : ""
          } text-black dark:text-white ${
            isRequiredError
              ? "ring-2 shadow-lg shadow-red-600/50 ring-red-600 dark:ring-red-600"
              : "ring-1 ring-button-light dark:ring-button-dark "
          } ring-inset focus:ring-link-light focus:dark:ring-link-dark 
        placeholder:text-gray-placeholder focus:ring-2 focus:ring-inset 
        sm:text-sm sm:leading-6 read-only:text-gray-400 read-only:ring-gray-500
        dark:read-only:text-gray-400 dark:read-only:ring-gray-500 dark:[color-scheme:dark]`}
        />
        {/* Icon for datepicker */}
        <div
          className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
          onClick={() => hiddenDateRef.current?.showPicker()}
        >
          <CalendarDaysIcon
            className="h-6 w-6 shrink-0 text-text-light dark:text-text-dark"
            aria-hidden="true"
            tabIndex={-1}
          />
        </div>
        {/* Hidden native date input */}
        <input
          {...props}
          type="date"
          ref={hiddenDateRef}
          onChange={handleDateChange}
          className="absolute inset-0 opacity-0 pointer-events-none dark:[color-scheme:dark]"
          tabIndex={-1}
        />
      </div>
    );
  }
);
DateInput.displayName = "DateInput";

export let InputWithInfo = React.forwardRef<HTMLInputElement, InputProps>(
  ({ type, description, isRequiredError, ...props }, ref) => {
    return (
      <div className="w-full">
        <div className="relative mt-2 rounded-md shadow-sm ">
          {
            <div className="absolute inset-y-0 right-2 flex items-center pl-3 group">
              <InformationCircleIcon className="text-black dark:text-white h-6 z-10 cursor-help" />
              <div
                className={`invisible group-hover:visible  absolute  z-10 space-y-1
                 bg-bg-light ml-2 dark:bg-bg-dark text-gray-light dark:text-gray-dark text-sm rounded px-4 py-2 w-auto left-full`}
                role="tooltip"
                aria-hidden="true"
              >
                {description}
              </div>
            </div>
          }
          <input
            type={type}
            ref={ref}
            {...props}
            className={`text-input dark:text-input-dark bg-bg-light dark:bg-bg-dark block w-full rounded-md border-0 py-1.5 text-black dark:text-white ${
              isRequiredError
                ? "ring-2 shadow-lg shadow-red-600/50 ring-red-600 dark:ring-red-600"
                : "ring-1 ring-button-light dark:ring-button-dark "
            } ring-inset focus:ring-link-light focus:dark:ring-link-dark 
            placeholder:text-gray-placeholder focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 disabled:text-gray-400 disabled:ring-gray-500`}
          />
        </div>
      </div>
    );
  }
);
InputWithInfo.displayName = "InputWithInfo";

export default Input;
