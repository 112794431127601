export const LANGUAGE_EN = "ecde5b94-6db9-4431-9f6e-53a25595c0f0";
export const LANGUAGE_DE = "ecde5b94-6db9-4431-9f6e-53a25595c0f1";
export const LANGUAGE_ES = "ecde5b94-6db9-4431-9f6e-53a25595c0f2";

export const LANGUAGE_ISO_EN = "en";
export const LANGUAGE_ISO_DE = "de";
export const LANGUAGE_ISO_ES = "es";

export const CSRF_COOKIE = "csrf";

export const PLACEHOLDER = "%PLACEHOLDER%";
