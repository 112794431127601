export default {
  requiredField: "Pflichtfeld",
  min3Chars: "Eingabe ist zu kurz (min. 3 Zeichen)",
  min6Chars: "Eingabe ist zu kurz (min. 6 Zeichen)",
  exactly5Chars: "Eingabe muss genau 5 Zeichen lang sein",
  exactly6Chars: "Eingabe muss genau 6 Zeichen lang sein",
  exactly19Chars: "Eingabe muss genau 19 Zeichen lang sein",
  max40Chars: "Eingabe ist zu lang (max. 40 Zeichen)",
  max100Chars: "Eingabe ist zu lang (max. 100 Zeichen)",
  max200Chars: "Eingabe ist zu lang (max. 200 Zeichen)",
  cardnumberSchema:
    "Kartennummer muss diesem Format entsprechen: XXXX XXXX XXXX XXXX",
  expiryDateSchema: "Datum muss diesem Format entsprechen: XX/XX",
  passwordRequirements:
    "Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben und ein Spezialzeichen enthalten.",
  invalidEntry: "Ungültige Eingabe",
  invalidHttpsUrl:
    "Bitte geben Sie eine gültige URL an (Beispiel: https://www.connect8.at)",
  invalidEmail: "Ungültige Email",
  passwordMatch: "Die Passwörter müssen übereinstimmen",
  shouldBeEmptyField: "Das Feld darf nicht befüllt sein",
  vatRegNoMustBeSetInEUCountry: "Ein EU Land muss eine UID Nummer angeben.",
};
