import { PLACEHOLDER } from "~/constants/constants";

export default {
  completedSuccess: "Task completed successfully!",
  errorOnCompletion: "There was an unexpected error",
  errorOnLoadingTasks: "Error on loading the tasks",
  nearlyExpiredUTP: "Expiring biddings",
  nearlyExpiredUTPBidderText: "One of your bids is expring",
  nearlyExpiredUTPsBidderText: `${PLACEHOLDER} of your bids are expiring.`,
  nearlyExpiredUTPCreatorText: "One bid for you is expring",
  nearlyExpiredUTPsCreatorText: `${PLACEHOLDER} bids for you are expiring.`,
  deliveryDecision: "Choose Delivery Method",
  deliveryDecisionText: "You have to choose a delivery method for one deal.",
  deliveryDecisionNumberText: `You have to choose a delivery method for ${PLACEHOLDER} deals.`,
  utpActionNeeded: "Offers for your Requests",
  utpActionNeededText: "One offer is waiting for your answer",
  utpsActionNeededText: `${PLACEHOLDER} offers are waiting for your answers.`,
  myUtpActionNeeded: "Answers for your Offers",
  myUtpActionNeededText:
    "One answer for one of your offers is waiting for your reaction.",
  myUtpsActionNeededText: `${PLACEHOLDER} anwers for your offers are waiting for your reaction.`,
};
