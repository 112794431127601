import { SEARCH_PARAM_ACTIONNEEDED } from "~/components/shopcomponents/FilterHeader";
import { LogisticOverviewType } from "~/routes/logistic.overview.$type/constants";
import { TradeOverviewType } from "~/routes/trade.overview.$type/constants";
import { TradeOverviewProductStateType } from "~/routes/trade/_constants";

export const SUBMIT_INTENT = "intent";

export const ROUTE_ADMIN = "/admin";
export const ROUTE_ADMIN_NEWEMPLOYEE = `${ROUTE_ADMIN}/newemployee`;
export const ROUTE_ADMIN_LOGINS = `${ROUTE_ADMIN}/logins`;
export const ROUTE_ADMIN_REDIS = `${ROUTE_ADMIN}/redis`;
export const ROUTE_ADMIN_INVOICES = `${ROUTE_ADMIN}/invoices`;
export const ROUTE_ADMIN_TRADEPRODUCT = `${ROUTE_ADMIN}/tradeproduct`;

export const ROUTE_REGISTERCOMPANY = "/registercompany/basics";

export const ROUTE_TRADEPRODUCTSHOP = "/trade/shop";
export const ROUTE_TRADEPRODUCT_DETAIL = "/trade/detail/";
export const ROUTE_TRADEPRODUCT_CREATE = "/trade/create";
export const ROUTE_TRADEPRODUCT_EDIT = "/trade/edit/";
export const ROUTE_TRADEPRODUCT_UTP_LOADER = "/trade/tradeproduct/utp/loader";
export const ROUTE_TRADEPRODUCT_POPULARTAGS_LOADER =
  "/trade/tradeproduct/populartags/loader";

export const ROUTE_TRADEOVERVIEW_BASE = "/trade/overview/";
export const ROUTE_TRADEOVERVIEW_MYREQUESTS = `${ROUTE_TRADEOVERVIEW_BASE}${TradeOverviewType.MyRequests}/${TradeOverviewProductStateType.Active}`;
export const ROUTE_TRADEOVERVIEW_MYREQUESTS_DONE = `${ROUTE_TRADEOVERVIEW_BASE}${TradeOverviewType.MyRequests}/${TradeOverviewProductStateType.Done}`;
export const ROUTE_TRADEOVERVIEW_MYREQUESTS_ACTIONNEEDED = `${ROUTE_TRADEOVERVIEW_BASE}${TradeOverviewType.MyRequests}/${TradeOverviewProductStateType.Active}?${SEARCH_PARAM_ACTIONNEEDED}=true`;
export const ROUTE_TRADEOVERVIEW_MYOFFERS = `${ROUTE_TRADEOVERVIEW_BASE}${TradeOverviewType.MyOffers}/${TradeOverviewProductStateType.Active}`;
export const ROUTE_TRADEOVERVIEW_MYOFFERS_DELIVERY = `${ROUTE_TRADEOVERVIEW_BASE}${TradeOverviewType.MyOffers}/delivery`;
export const ROUTE_TRADEOVERVIEW_MYOFFERS_ACTIONNEEDED = `${ROUTE_TRADEOVERVIEW_BASE}${TradeOverviewType.MyOffers}/${TradeOverviewProductStateType.Active}?${SEARCH_PARAM_ACTIONNEEDED}=true`;

export const ROUTE_TRADEOVERVIEW_DETAILLOADER = "/trade/utp/history/loader";

export const ROUTE_LOGISTIC = "/logistic/requests";
export const ROUTE_LOGISTIC_DETAIL = "/logistic/detail/";
export const ROUTE_LOGISTICOVERVIEW_BASE = "/logistic/overview/";
export const ROUTE_LOGISTICOVERVIEW_MYREQUESTS = `${ROUTE_LOGISTICOVERVIEW_BASE}${LogisticOverviewType.MyRequests}`;
export const ROUTE_LOGISTICOVERVIEW_MYOFFERS = `${ROUTE_LOGISTICOVERVIEW_BASE}${LogisticOverviewType.MyOffers}`;
export const ROUTE_LOGISTICOVERVIEW_DONE = `${ROUTE_LOGISTICOVERVIEW_BASE}${LogisticOverviewType.Done}`;
export const ROUTE_LOGISTICOVERVIEW_PAST = `${ROUTE_LOGISTICOVERVIEW_BASE}${LogisticOverviewType.Past}`;

export const ROUTE_LOGISTICOVERVIEW_HISTORYLOADER =
  "/logistic/offer/history/loader";
export const ROUTE_LOGISTIC_OFFERLOADER =
  "/logistic/transportrequest/offer/loader";

export const ROUTE_COMPANYSETTINGS = "/companysettings/account";
export const ROUTE_COMPANYSETTINGS_PAYMENT = "/companysettings/paymentmethod";
export const ROUTE_COMPANYSETTINGS_USERS = "/companysettings/users";
