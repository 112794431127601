import React, { useEffect, useState } from "react";
import type { NumberRange } from "~/types/NumberRange";
import { getValidNumber } from "~/utils/misc";

export interface NumberRangeInputProps {
  value: NumberRange | undefined;
  onChange: ({
    min,
    max,
  }: {
    min: number | undefined;
    max: number | undefined;
  }) => void;
  symbol?: string;
  assignedToLabel: string;
  labelClassName?: string;
}

let NumberRangeInput: React.FC<NumberRangeInputProps> = ({
  value,
  onChange,
  symbol,
  assignedToLabel,
  labelClassName,
}) => {
  let [minVal, setMinVal] = useState<string>(value?.min?.toString() ?? "");
  let [maxVal, setMaxVal] = useState<string>(value?.max?.toString() ?? "");

  useEffect(() => {
    let minNumber = getValidNumber(minVal);
    let maxNumber = getValidNumber(maxVal);
    onChange({ min: minNumber, max: maxNumber });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minVal, maxVal]);

  return (
    <div className="flex items-center">
      <label
        htmlFor={assignedToLabel}
        className={`${
          labelClassName ??
          "block text-sm font-medium leading-6 text-black dark:text-white"
        }`}
      >
        {assignedToLabel}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm ml-2">
        {symbol ? (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            {symbol}
          </div>
        ) : (
          <></>
        )}

        <input
          id={assignedToLabel}
          aria-label={assignedToLabel}
          type="text"
          placeholder="Min"
          value={minVal}
          className={`bg-gray-700 block w-full rounded-md border-0 py-1.5 ${
            symbol ? "pl-7" : ""
          } text-black dark:text-white ring-1 ring-inset ring-gray-300 
            placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
          onChange={(event) => {
            setMinVal(event.target.value);
          }}
        />
      </div>
      <div className="relative mt-2 rounded-md shadow-sm ml-1">
        {symbol ? (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            {symbol}
          </div>
        ) : (
          <></>
        )}
        <input
          id={assignedToLabel + "-to"}
          aria-label={assignedToLabel + "-to"}
          type="text"
          placeholder="Max"
          value={maxVal}
          className={`bg-gray-700 block w-full rounded-md border-0 py-1.5 ${
            symbol ? "pl-7" : ""
          } text-black dark:text-white ring-1 ring-inset ring-gray-300 
            placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
          onChange={(event) => {
            setMaxVal(event.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default NumberRangeInput;
