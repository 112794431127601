import { useRef, useState } from "react";

export default function BackGroundVideoWorldRoute() {
  const [isSmall, setIsSmall] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const handleVideoEnd = () => {
    setIsSmall(true); // Set the state to true when video ends
  };

  return (
    <>
      <div
        className={`flex items-center justify-center transition-all duration-500 ${
          isSmall ? "h-1/2" : "h-full"
        }`}
      >
        <video
          ref={videoRef}
          src={
            "https://storage.googleapis.com/gke_europe_central_connect8_public/videos/Final.mp4"
          }
          autoPlay
          loop={false}
          muted
          onEnded={handleVideoEnd}
          className={`flex items-center justify-center transition-all duration-500 ${
            isSmall ? "w-1/2" : "w-full"
          }`}
        />
      </div>
    </>
  );
}
