export default {
  requiredField: "required field",
  min3Chars: "too short (at least 3 characters)",
  min6Chars: "too short (at least 6 characters)",
  exactly5Chars: "input has to be exactly 5 characters",
  exactly6Chars: "input has to be exactly 6 characters",
  exactly19Chars: "input has to be exactly 19 characters",
  max40Chars: "too long (max 40 characters)",
  max100Chars: "too long (max 100 characters)",
  max200Chars: "too long (max 200 characters)",
  cardnumberSchema: "Enter Cardnumber after schema XXXX XXXX XXXX XXXX",
  expiryDateSchema: "Enter expiry date in this format: XX/XX",
  passwordRequirements:
    "Password needs to contain at least one uppercase, one lowercase and one special character",
  invalidEntry: "Invalid Entry",
  invalidHttpsUrl: "Please enter a valid URL like https://www.connect8.at",
  invalidEmail: "invalid email",
  passwordMatch: "The passwords must match!",
  shouldBeEmptyField: "input should be empty",
  vatRegNoMustBeSetInEUCountry: "Country of EU must provide a UID.",
};
