import type { Route } from ".react-router/types/app/routes/_index/+types/route";
import { CheckCircleIcon, PlusCircleIcon } from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, redirect, useLoaderData } from "react-router";
import Button from "~/components/buttons/Button";
import { GeneralErrorBoundary } from "~/components/errorboundary";
import LandingPage from "~/components/landingPage";
import { CompanyAddressType } from "~/constants/prismaEnums";
import { COOKIE } from "~/constants/registrationconstants";
import {
  ROUTE_ADMIN,
  ROUTE_COMPANYSETTINGS_PAYMENT,
  ROUTE_TRADEOVERVIEW_MYOFFERS,
  ROUTE_TRADEOVERVIEW_MYOFFERS_ACTIONNEEDED,
  ROUTE_TRADEOVERVIEW_MYOFFERS_DELIVERY,
  ROUTE_TRADEOVERVIEW_MYREQUESTS,
  ROUTE_TRADEOVERVIEW_MYREQUESTS_ACTIONNEEDED,
  SUBMIT_INTENT,
} from "~/constants/routeConstants";
import {
  getAllActiveCompanyAddressesByCompanyID,
  getDefaultBillingAddress,
} from "~/DatabaseInteractionClasses/CompanyAddressDIC.server";
import { updateRolesFromCompany } from "~/DatabaseInteractionClasses/CompanyDIC.server";
import { getTop6Events } from "~/DatabaseInteractionClasses/EventShortsDIC.server";
import { getTop6News } from "~/DatabaseInteractionClasses/NewsShortsDIC.server";
import { getPaymentMethodsFromDB } from "~/DatabaseInteractionClasses/PaymentMethodDIC.server";
import { getAllStepsNotDone } from "~/DatabaseInteractionClasses/TutorialStepsDIC.server";
import {
  getCountOfMyNearlyExpiredUserTradeProducts,
  getCountOfNearlyExpiredUserTradeProductsForMe,
  getCountOfPendingDeliveryDecisions,
  getCountOfUserTradeProductsWhichNeedMyAction,
} from "~/DatabaseInteractionClasses/UserTradeProductDIC.server";
import { AUTH_COOKIEKEY, isUserAlreadyLoggedIn } from "~/services/auth.server";
import {
  deleteCachedCompanyByCompanyID,
  getCachedCompanyByCompanyID,
  getCachedCompanyForLoginID,
  getCachedInternalEmployeeByLoginID,
  getCachedLoginByLoginID,
} from "~/services/cache.server";
import { getSession } from "~/services/session.server";
import type { AuthenticatedIDs } from "~/types/AuthenticatedIDs";
import StartPage from "./StartPage";

const INTENT_ROLES = "roles";
const INTENT_ISBUYER = "buyer";
const INTENT_ISSELLER = "seller";
const INTENT_ISCARRIER = "carrier";

export type importantTask = {
  title: string;
  description: string;
  link: string;
  isDone: boolean;
  placeholderValue?: string;
};

export const loader = async ({ request }: Route.LoaderArgs) => {
  let session = await getSession(request.headers.get(COOKIE));
  let authenticatedIDs: AuthenticatedIDs = session.get(AUTH_COOKIEKEY);

  if (!authenticatedIDs) {
    return null;
  }
  let admin = await getCachedInternalEmployeeByLoginID(
    authenticatedIDs.loginID
  );
  if (admin?.is_admin === true) {
    throw redirect(ROUTE_ADMIN);
  }
  let login = await getCachedLoginByLoginID(authenticatedIDs.loginID);
  let openSteps = await getAllStepsNotDone(authenticatedIDs.loginID);
  let company = await getCachedCompanyForLoginID(authenticatedIDs.loginID);
  let news = await getTop6News();
  let events = await getTop6Events();

  let importantTasks: importantTask[] = [];
  let hasCompanyAdminRights = false;
  if (company) {
    hasCompanyAdminRights = company.adminMails.includes(login.email);
    if (hasCompanyAdminRights) {
      if (company.is_buyer) {
        let deliveryAddresses = await getAllActiveCompanyAddressesByCompanyID(
          company.id,
          CompanyAddressType.Delivery
        );
        let taskDeliveryAddress: importantTask = {
          description: "createDeliveryTaskText",
          isDone: deliveryAddresses.length > 0,
          link: "./addressmanagement/Delivery",
          title: "createDeliveryTask",
        };
        importantTasks.push(taskDeliveryAddress);
      }
      if (company.is_seller || company.is_carrier) {
        let paymentMethods = await getPaymentMethodsFromDB(company.id);
        let billingaddress = await getDefaultBillingAddress(company.id);
        let taskPaymentMethod: importantTask = {
          description: "createPaymentTaskText",
          isDone: paymentMethods.length > 0,
          link: `.${ROUTE_COMPANYSETTINGS_PAYMENT}`,
          title: "createPaymentTask",
        };
        importantTasks.push(taskPaymentMethod);
        let taskBillingAddress: importantTask = {
          description: "createBillingTaskText",
          isDone: billingaddress ? true : false,
          link: "./addressmanagement/Billing",
          title: "createBillingTask",
        };
        importantTasks.push(taskBillingAddress);
      }
      if (company.is_seller) {
        let loadingAddresses = await getAllActiveCompanyAddressesByCompanyID(
          company.id,
          CompanyAddressType.Loading
        );

        let taskLoadingAddress: importantTask = {
          description: "createLoadingTaskText",
          isDone: loadingAddresses.length > 0,
          link: "./addressmanagement/Loading",
          title: "createLoadingTask",
        };
        importantTasks.push(taskLoadingAddress);
      }
    }
  }

  if (login.user_id) {
    let expiredUTPCount = await getCountOfMyNearlyExpiredUserTradeProducts(
      login.user_id,
      7 // TODO maybe a setting?
    );
    if (expiredUTPCount > 0) {
      importantTasks.push({
        description:
          expiredUTPCount === 1
            ? "task:nearlyExpiredUTPBidderText"
            : "task:nearlyExpiredUTPsBidderText",
        isDone: false,
        link: `.${ROUTE_TRADEOVERVIEW_MYOFFERS}`,
        title: "task:nearlyExpiredUTP",
        placeholderValue: `${expiredUTPCount}`,
      });
    }

    let expiredUTPCreatorCount =
      await getCountOfNearlyExpiredUserTradeProductsForMe(
        login.user_id,
        7 // TODO maybe a setting?
      );
    if (expiredUTPCreatorCount > 0) {
      importantTasks.push({
        description:
          expiredUTPCreatorCount === 1
            ? "task:nearlyExpiredUTPCreatorText"
            : "task:nearlyExpiredUTPsCreatorText",
        isDone: false,
        link: `.${ROUTE_TRADEOVERVIEW_MYREQUESTS}`,
        title: "task:nearlyExpiredUTP",
        placeholderValue: `${expiredUTPCreatorCount}`,
      });
    }

    let deliveryDecisionCount = await getCountOfPendingDeliveryDecisions(
      login.user_id
    );
    if (deliveryDecisionCount > 0) {
      importantTasks.push({
        description:
          deliveryDecisionCount === 1
            ? "task:deliveryDecisionText"
            : "task:deliveryDecisionNumberText",
        isDone: false,
        link: `.${ROUTE_TRADEOVERVIEW_MYOFFERS_DELIVERY}`,
        title: "task:deliveryDecision",
        placeholderValue: `${deliveryDecisionCount}`,
      });
    }

    let { countMyTradeproducts, countMyUTPs } =
      await getCountOfUserTradeProductsWhichNeedMyAction(login.user_id);
    if (countMyTradeproducts > 0) {
      importantTasks.push({
        description:
          countMyTradeproducts === 1
            ? "task:utpActionNeededText"
            : "task:utpsActionNeededText",
        isDone: false,
        link: `.${ROUTE_TRADEOVERVIEW_MYREQUESTS_ACTIONNEEDED}`,
        title: "task:utpActionNeeded",
        placeholderValue: `${countMyTradeproducts}`,
      });
    }
    if (countMyUTPs > 0) {
      importantTasks.push({
        description:
          countMyUTPs === 1
            ? "task:myUtpActionNeededText"
            : "task:myUtpsActionNeededText",
        isDone: false,
        link: `.${ROUTE_TRADEOVERVIEW_MYOFFERS_ACTIONNEEDED}`,
        title: "task:myUtpActionNeeded",
        placeholderValue: `${countMyUTPs}`,
      });
    }
  }

  importantTasks = importantTasks
    .sort((a, b) => Number(a.isDone) - Number(b.isDone))
    .slice(0, 5);

  return {
    IsUserAlreadyLoggedIn: Boolean(authenticatedIDs),
    authenticatedIDs,
    openSteps,
    company,
    news,
    events,
    importantTasks,
    hasCompanyAdminRights,
  };
};

export const action = async ({ request }: Route.ActionArgs) => {
  let ids = await isUserAlreadyLoggedIn(request);
  if (ids) {
    let formData = await request.formData();
    let intent = formData.get(SUBMIT_INTENT);

    switch (intent) {
      case INTENT_ROLES: {
        let isBuyer = String(formData.get(INTENT_ISBUYER)) === "true";
        let isSeller = String(formData.get(INTENT_ISSELLER)) === "true";
        let isCarrier = String(formData.get(INTENT_ISCARRIER)) === "true";

        let company = await getCachedCompanyForLoginID(ids.login.loginID);
        if (company) {
          await updateRolesFromCompany(
            company.id,
            isBuyer,
            isSeller,
            isCarrier
          );
          await deleteCachedCompanyByCompanyID(company.id);
          await getCachedCompanyByCompanyID(company.id);
        }
        break;
      }
    }
  }
  return null;
};

export default function IndexRoute() {
  let data = useLoaderData<typeof loader>();
  let { t } = useTranslation();

  let [isBuyer, setIsBuyer] = useState(false);
  let [isSeller, setIsSeller] = useState(false);
  let [isCarrier, setIsCarrier] = useState(false);

  let isCompanyAccount = data?.hasCompanyAdminRights ?? false;

  let showRoleSelection =
    !data?.company?.is_buyer &&
    !data?.company?.is_seller &&
    !data?.company?.is_carrier &&
    isCompanyAccount;

  return (
    <>
      {data?.IsUserAlreadyLoggedIn ? (
        showRoleSelection ? (
          <Form method="POST">
            <div className="mx-auto max-w-4xl px-6 max-lg:text-center lg:max-w-7xl lg:px-8">
              <h1 className="text-balance text-text-light dark:text-text-dark text-5xl font-semibold tracking-tight  sm:text-6xl lg:text-pretty">
                {t("chooseRoleTitle")}
              </h1>
              <p className="mt-6 max-w-2xl text-pretty text-lg font-medium text-gray-light dark:text-gray-dark max-lg:mx-auto sm:text-xl/8">
                {t("chooseRoleText")}
              </p>
            </div>
            <div className="relative pt-16 sm:pt-24">
              <div className="relative mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
                <div className="grid grid-cols-1 gap-10 lg:grid-cols-3">
                  <div
                    key={"käufer"}
                    className="-m-2 grid grid-cols-1 rounded-[2rem] max-lg:mx-auto max-lg:w-full max-lg:max-w-md"
                  >
                    <div className="grid grid-cols-1 rounded-[2rem] border-2 p-2 border-black dark:border-white hover:border-link-light hover:dark:border-link-dark">
                      <div className="rounded-3xl bg-bg-light dark:bg-bg-dark p-10 pb-9">
                        <h2 className="font-semibold text-button-light dark:text-button-dark text-center">
                          {t("buyer")} <span className="sr-only">plan</span>
                        </h2>
                        <p className="mt-2 text-pretty text-sm/6 text-gray-light dark:text-gray-dark text-center">
                          {t("createTrades")}
                        </p>
                        <div className="mt-8 flex items-center justify-center">
                          {!isBuyer ? (
                            <button
                              type="button"
                              onClick={() => {
                                setIsBuyer(!isBuyer);
                              }}
                            >
                              <PlusCircleIcon
                                className="h-6 w-6 shrink-0 text-red-700 dark:text-red-300"
                                aria-hidden="true"
                              />
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() => {
                                setIsBuyer(!isBuyer);
                              }}
                            >
                              <CheckCircleIcon
                                className="h-6 w-6 shrink-0 text-green-700 dark:text-green-300"
                                aria-hidden="true"
                              />
                            </button>
                          )}
                        </div>
                        <div className="mt-8">
                          <h3 className="text-sm/6 font-medium text-text-light dark:text-text-dark">
                            {t("featureActivation")}
                          </h3>
                          <ul className="mt-3 space-y-3">
                            <li
                              key={"Erstelle Handelsanfragen"}
                              className="group flex items-start gap-4 text-sm/6 text-gray-light dark:text-gray-dark"
                            >
                              <span className="inline-flex h-6 items-center">
                                <PlusIcon
                                  aria-hidden="true"
                                  className="size-4 fill-gray-light dark:fill-gray-dark group-data-[disabled]:fill-gray-300"
                                />
                              </span>

                              {"Featuer1"}
                            </li>

                            <li
                              key={"Angebotsannahme"}
                              className="group flex items-start gap-4 text-sm/6 text-gray-light dark:text-gray-dark"
                            >
                              <span className="inline-flex h-6 items-center">
                                <PlusIcon
                                  aria-hidden="true"
                                  className="size-4 fill-gray-light dark:fill-gray-dark group-data-[disabled]:fill-gray-300"
                                />
                              </span>

                              {"Feature2"}
                            </li>

                            <li
                              key={"Blubb"}
                              className="group flex items-start gap-4 text-sm/6 text-gray-light dark:text-gray-dark"
                            >
                              <span className="inline-flex h-6 items-center">
                                <PlusIcon
                                  aria-hidden="true"
                                  className="size-4 fill-gray-light dark:fill-gray-dark group-data-[disabled]:fill-gray-300"
                                />
                              </span>

                              {"Feature3"}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    key={"verkäufer"}
                    className="-m-2 grid grid-cols-1 rounded-[2rem] max-lg:mx-auto max-lg:w-full max-lg:max-w-md"
                  >
                    <div className="grid grid-cols-1 rounded-[2rem] border-2 p-2 border-black dark:border-white hover:border-link-light hover:dark:border-link-dark">
                      <div className="rounded-3xl bg-bg-light dark:bg-bg-dark p-10 pb-9">
                        {" "}
                        <h2 className="font-semibold text-button-light dark:text-button-dark text-center">
                          {t("seller")} <span className="sr-only">plan</span>
                        </h2>
                        <p className="mt-2 text-pretty text-sm/6 text-gray-light dark:text-gray-dark text-center">
                          {t("createOffer")}
                        </p>
                        <div className="mt-8 flex items-center justify-center">
                          {!isSeller ? (
                            <button
                              type="button"
                              onClick={() => {
                                setIsSeller(!isSeller);
                              }}
                            >
                              <PlusCircleIcon
                                className="h-6 w-6 shrink-0 text-red-700 dark:text-red-300"
                                aria-hidden="true"
                              />
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() => {
                                setIsSeller(!isSeller);
                              }}
                            >
                              <CheckCircleIcon
                                className="h-6 w-6 shrink-0 text-green-700 dark:text-green-300"
                                aria-hidden="true"
                              />
                            </button>
                          )}
                        </div>
                        <div className="mt-8">
                          <h3 className="text-sm/6 font-medium text-text-light dark:text-text-dark">
                            {t("featureActivation")}
                          </h3>
                          <ul className="mt-3 space-y-3">
                            <li
                              key={"blubb1"}
                              className="group flex items-start gap-4 text-sm/6 text-gray-light dark:text-gray-dark"
                            >
                              <span className="inline-flex h-6 items-center">
                                <PlusIcon
                                  aria-hidden="true"
                                  className="size-4 fill-gray-light dark:fill-gray-dark group-data-[disabled]:fill-gray-300"
                                />
                              </span>

                              {"Feature1"}
                            </li>

                            <li
                              key={"blubb2"}
                              className="group flex items-start gap-4 text-sm/6 text-gray-light dark:text-gray-dark"
                            >
                              <span className="inline-flex h-6 items-center">
                                <PlusIcon
                                  aria-hidden="true"
                                  className="size-4 fill-gray-light dark:fill-gray-dark group-data-[disabled]:fill-gray-300"
                                />
                              </span>

                              {"Feature2"}
                            </li>

                            <li
                              key={"blubb3"}
                              className="group flex items-start gap-4 text-sm/6 text-gray-light dark:text-gray-dark"
                            >
                              <span className="inline-flex h-6 items-center">
                                <PlusIcon
                                  aria-hidden="true"
                                  className="size-4 fill-gray-light dark:fill-gray-dark group-data-[disabled]:fill-gray-300"
                                />
                              </span>

                              {"Feature3"}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    key={"spedition"}
                    className="-m-2 grid grid-cols-1 rounded-[2rem] max-lg:mx-auto max-lg:w-full max-lg:max-w-md"
                  >
                    <div className="grid grid-cols-1 rounded-[2rem] border-2 p-2 border-black dark:border-white hover:border-link-light hover:dark:border-link-dark">
                      <div className="rounded-3xl bg-bg-light dark:bg-bg-dark p-10 pb-9">
                        {" "}
                        <h2 className="font-semibold text-button-light dark:text-button-dark text-center">
                          {t("carrier")} <span className="sr-only">plan</span>
                        </h2>
                        <p className="mt-2 text-pretty text-sm/6 text-gray-light dark:text-gray-dark text-center">
                          {t("createTransportOffer")}
                        </p>
                        <div className="mt-8 flex items-center justify-center">
                          {!isCarrier ? (
                            <button
                              type="button"
                              onClick={() => {
                                setIsCarrier(!isCarrier);
                              }}
                            >
                              <PlusCircleIcon
                                className="h-6 w-6 shrink-0 text-red-700 dark:text-red-300"
                                aria-hidden="true"
                              />
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() => {
                                setIsCarrier(!isCarrier);
                              }}
                            >
                              <CheckCircleIcon
                                className="h-6 w-6 shrink-0 text-green-700 dark:text-green-300"
                                aria-hidden="true"
                              />
                            </button>
                          )}
                        </div>
                        <div className="mt-8">
                          <h3 className="text-sm/6 font-medium text-text-light dark:text-text-dark">
                            {t("featureActivation")}
                          </h3>
                          <ul className="mt-3 space-y-3">
                            <li
                              key={"blub4"}
                              className="group flex items-start gap-4 text-sm/6 text-gray-light dark:text-gray-dark"
                            >
                              <span className="inline-flex h-6 items-center">
                                <PlusIcon
                                  aria-hidden="true"
                                  className="size-4 fill-gray-light dark:fill-gray-dark group-data-[disabled]:fill-gray-300"
                                />
                              </span>

                              {"Feature1"}
                            </li>

                            <li
                              key={"blubb5"}
                              className="group flex items-start gap-4 text-sm/6 text-gray-light dark:text-gray-dark"
                            >
                              <span className="inline-flex h-6 items-center">
                                <PlusIcon
                                  aria-hidden="true"
                                  className="size-4 fill-gray-light dark:fill-gray-dark group-data-[disabled]:fill-gray-300"
                                />
                              </span>

                              {"Feature2"}
                            </li>

                            <li
                              key={"blubb6"}
                              className="group flex items-start gap-4 text-sm/6 text-gray-light dark:text-gray-dark"
                            >
                              <span className="inline-flex h-6 items-center">
                                <PlusIcon
                                  aria-hidden="true"
                                  className="size-4 fill-gray-light dark:fill-gray-dark group-data-[disabled]:fill-gray-300"
                                />
                              </span>

                              {"Feature3"}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8 flex items-center justify-center">
              <input
                type="hidden"
                name={INTENT_ISBUYER}
                value={String(isBuyer)}
                key={INTENT_ISBUYER}
              />
              <input
                type="hidden"
                name={INTENT_ISSELLER}
                value={String(isSeller)}
                key={INTENT_ISSELLER}
              />
              <input
                type="hidden"
                name={INTENT_ISCARRIER}
                value={String(isCarrier)}
                key={INTENT_ISCARRIER}
              />
              <Button type="submit" name={SUBMIT_INTENT} value={INTENT_ROLES}>
                {t("completeRoleSelection")}
              </Button>
            </div>
          </Form>
        ) : (
          <>
            <StartPage
              isCompanyAccount={true}
              news={data.news}
              events={data.events}
              importantTasks={data.importantTasks}
            ></StartPage>
          </>
        )
      ) : (
        <LandingPage />
      )}
    </>
  );
}

export function ErrorBoundary() {
  return <GeneralErrorBoundary />;
}
