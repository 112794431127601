export default {
  save: "Save",
  confirm: "Confirm",
  back: "Back",
  yes: "Yes",
  cancel: "Cancel",
  confirmDeactivationTitle: "Confirm deactivation?",
  confirmDeactivationText:
    "Are you sure you want to deactivate your account and delete all user specific data? This operation cannot be undone!",
  confirmDeactivationButton: "Yes, delete my account",
  password: "Password",
  passwordChangeSuccess: "Password changed successfully!",
  profileChangeSuccess: "Profile data was changed successfully!",
  languageChangeSuccess: "Language settings were changed successfully",
  changePassword: "Change password",
  changePasswordText: "Update your password associated with your account.",
  currentPassword: "Current password",
  newPassword: "New password",
  confirmPassword: "Confirm password",
  deleteAccount: "Delete account",
  deleteAccountText:
    "No longer want to use our service? You can delete your account here. This action is not reversible. All information related to this account will be deleted permanently.",
  companyInformation: "Company Information",
  userInformation: "User Information",
  companyInformationText: "You can change company information here.",
  userInformationText: "you can change user information here.",
  companyLanguageInformation: "Languages",
  companyLanguageInformationText: "You can change language settings here",
  allowedFileTypes: "JPG, GIF or PNG. 1MB max.",
  companyName: "Company Name",
  street: "Street",
  place: "Place",
  country: "Country",
  zip: "ZIP",
  vat: "VAT Registration No",
  email: "E-Mail",
  uploadAFile: "Upload a file",
  account: "Account",
  users: "Users",
  userUpdatedSuccessfully: "User updated successfully!",
  userCreatedSuccessfully: "User created successfully!",
  companyAddressUpdatedSuccessfully: "Address updated successfully",
  companyAddressCreatedSuccessfully: "Address created successfully!",
  userAccounts: "User Accounts",
  forename: "Forename",
  lastname: "Lastname",
  position: "Position",
  edit: "Edit",
  active: "Active",
  isPublic: "Is Public",
  editUser: "Edit User",
  registerNewUser: "Register new User",
  editCompanyAddress: "Edit Address",
  registerNewCompanyAddress: "Create new Address",
  shop: "Shop",
  connect8trading: "Connect8 Trading",
  openProductRequests: "Open Product Requests",
  tradeOverview: "Trade Overview",
  tradeMyRequests: "My Requests",
  tradeMyOffers: "My Offers",
  tradeRejected: "Rejected",
  tradeExpired: "Expired",
  tradeDelivery: "Trade Delivery",
  tradeComplete: "Trade Complete",
  newProductRequest: "New Product Request",
  connect8Logistic: "Connect8 Logistics",
  openLogisticRequests: "Open Delivery Requests",
  myLogisticRequests: "My Requests",
  myLogisticOffers: "My Offers",
  currentLogistics: "Current Transports",
  doneLogistics: "Done Transports",
  pastLogistics: "Past Transports",
  faq: "FAQ",
  management: "Administration",
  salesdashboard: "Dashboard",
  invoicesoverview: "Invoices",
  logout: "Logout",
  tasks: "Tasks",
  taskschema: "Taskschema",
  registerUser: "Register User",
  yourQuickfilters: "Your Quickfilters",
  addressManagement: "Address Management",
  deliveryAddress: "Delivery Address",
  delivery_address_id: "Delivery Address",
  billingAddress: "Billing Address",
  loadingAddress: "Loading Address",
  deliveryAddresses: "Delivery Addresses",
  billingAddresses: "Billing Addresses",
  loadingAddresses: "Loading Addresses",
  agreedplaceAddresses: "Agreed Places",
  submit: "Submit",
  loading: "Loading",
  processing: "Processing",
  path: "Path",
  processingData: "Processing Data",
  emailSentSuccessfully: "Email was sent successfully",
  paymentMethod: "Paymentmethods",
  incoterm: "Incoterm",
  loadingDate: "Loading Date",
  loadingDateFrom: "Loading Date from",
  loadingDateTo: "Loading Date to",
  deliveryDate: "Delivery Date",
  deliveryDateFrom: "Delivery Date from",
  deliveryperiodfrom: "Delivery Date from",
  deliveryDateTo: "Delivery Date to",
  deliveryperiodto: "Delivery Date to",
  state: "State",
  price: "Price",
  message: "Message",
  faqOffer: "Read our FAQ to offers",
  faqDelivery: "Read our FAQ to delivery",
  faqDone: "Read our FAQ to completed trades",
  goToOverview: "Go to overview",
  desiredPricePerUnit: "Desired Price/Unit",
  description: "Description",
  send: "Send",
  selfDelivery: "Self",
  connect8Delivery: "Connect8 Logistics",
  tradeCounter: "Counter",
  tradeDecline: "Decline",
  tradeAccept: "Accept",
  close: "Close",
  existingOfferText:
    "You already created an offer. Go to the overview to create more.",
  confirmPaymentMethodActivationTitle: "Confirm paymentmethod?",
  confirmPaymentMethodActivationText:
    "By accepting this dialog, you agree to the payment agreement and the payment model. You can revoke this at any time as stated in the payment agreement.",
  confirmPaymentMethodDeletionTitle: "Delete paymentmethod?",
  confirmPaymentMethodDeletionText:
    "Are you sure that you want to delete the paymentmethod? This operation cannot be undone!",
  confirmTradeProductDeletionTitle: "Delete product request?",
  confirmTradeProductDeletionText:
    "Are you sure that you want to delete your product request?",
  existingOfferTextForMyTradeProduct:
    "There are offers for this item. Go to the overview to check them out.",
  noOfferText: "There are no offers yet!",
  default: "Default",
  isemailnotification: "E-Mail notifications",
  tutorial: "Tutorial",
  loadingplace: "Loadingplace",
  unloadingplace: "Unloadingplace",
  goods: "Goods",
  details: "Details",
  makeOffer: "Make Offer",
  downloadAttachments: "Download",
  firststep: "First steps",
  firststepdescription:
    "Here are some first steps - some are necessary for you to get full access to all functions.",
  addressType: "Addresstype",
  noPaymentMethodAvailableCantBid:
    "There is no payment method or billing address in your company account. Please provide a payment method and a billing address before you can start bidding.",
  validDateUntil: "Offer valid until",
  offerNotValidAnymore: "This offer is not valid anymore",
  no: "No",
  productCategory: "product category",
  product_category_id: "product category",
  quantityUnit: "quantity/unit",
  goodsValue: "goods value",
  weightNetGross: "weight (net/gross)",
  company: "company",
  contactPerson: "contact person",
  createdAt: "created at",
  lastPrice: "current price",
  notificationFrom: "Notification from",
  hello: "Hello",
  invitationText:
    "You were invited to trade on Connect8! Just click the link and you will be forwarded to the product. If you got no Account yet, you have to register first.",
  inviteToConnect8: "Invitation to",
  source: "source",
  currentPrice: "current price",
  currentPriceTotal: "current price total",
  validUntil: "valid until",
  loadingFrom: "loading from",
  loadingTo: "loading to",
  deliveryFrom: "delivery from",
  deliveryTo: "delivery to",
  weightNet: "weight net",
  weightGross: "weight gross",
  quantity: "quantity",
  unit: "unit",
  unit_id: "unit",
  loadingMeter: "loadingmeter",
  cubicMeter: "cubicmeter",
  length: "length",
  width: "width",
  height: "height",
  companyDetails: "Companydetails",
  homepage: "Homepage",
  videoURL: "Video ID",
  shortDescription: "Short description",
  offer: "offer",
  offers: "offers",
  noResultFilter: "No results... Try expanding the search",
  noResult: "No data",
  actualInvoices: "Open Invoices",
  archivedInvoices: "Archived Invoices",
  problemInvoices: "Not billable Invoices",
  activateCompany: "Activate Company",
  joinConnect8: "Contact following Person",
  invoiceType: "type",
  date: "date",
  amount: "amount",
  tax: "tax",
  taxAmount: "tax amount",
  invoice: "Invoice",
  Invoices: "Invoices",
  InvoicesDescription: "Here you can find all the invoices of your company.",
  text2FA:
    "Two-Factor Authentication (2FA) is a security mechanism that adds an extra layer of protection to secure your online accounts. In addition to the password you use, 2FA requires a second verification step. This could be a one-time password sent to your mobile phone, an app like Google Authenticator, or a physical security device.",
  security2FA:
    "2FA makes it more difficult for potential attackers to access your account, even if they know your password. It provides a more effective defense against hacking and unauthorized access to sensitive information.",
  logisticRequestTitle: "Trades to be Shipped",
  logisticRequestDescription:
    "Here you can find all the Trades which needs to be shipped",
  productImage: "Image",
  productDescription: "Product Description",
  productProperties: "Product Properties",
  deliveryInformation: "Delivery Information",
  documents: "Documents",
  title: "Title",
  createRequest: "Create Request",
  updateRequest: "Update Request",
  forgotPassword: "Forgot password",
  forgotPasswordText: "No worries, we'll send you reset instructions.",
  backToLogin: "Back to Login",
  checkYourEMail: "Check your email",
  checkYourEMailText: "We've sent you a code to verify your email address.",
  passwordReset: "Password Reset",
  dontWorry: "No worries. It happens all the time.",
  skipAll: "Or skip all these steps",
  attachment: "Attachment",
  attachments: "Attachments",
  loadingMessage: "loading...",
  none: "none",
  chooseRoleTitle: "Choose your role",
  chooseRoleText:
    "Just set up which role your company want to fullfil in our App. You can change this at any time in the companysettings.",
  buyer: "Buyer",
  createTrades: "Create a new Producttrade",
  featureActivation: "Following features will be activated:",
  seller: "Seller",
  carrier: "Carrier",
  createOffer: "Create a new offer for trades",
  completeRoleSelection: "Complete role selection",
  createTransportOffer: "Create a new offer for transports",
  news: "News",
  newsText: "We try to keep you updated with some relevant News!",
  importantTasks: "Important Tasks",
  importantTasksText: "This tasks must be completed to activate all features!",
  upcomingEvents: "Upcoming Events",
  upcomingEventsText:
    "Information about upcoming updates, maintanance and much more!",
  createDeliveryTask: "Create delivery address",
  createDeliveryTaskText: "You must provide at least one delivery address!",
  createPaymentTask: "Create payment method",
  createPaymentTaskText: "You must provide at least one payment method!",
  createBillingTask: "Create billing address",
  createBillingTaskText: "You must provide at least one billing address!",
  createLoadingTask: "Create loading address",
  createLoadingTaskText: "You must provide at least one loading address!!",
  total: "total",
  popular: "Popular",
  newest: "Newest",
  lowestPrice: "Lowest Price",
  highestPrice: "Highest Price",
  filter: "Filter",
  filters: "Filters",
  clearAll: "Clear all",
  sort: "Sort",
  qrCode: "QR Code",
  login: "Login",
  register: "Register",
  yourProfile: "Your Profile",
  selectOption: "Select option...",
  accountLanguage: "Account Language",
  tradeProductLanguages: "Languages for Product Requests",
  pleaseSelectAValue: "Please select a value",
  webcamNotFoundInfo:
    "No webcams found. Please connect a webcam and refresh the page.",
  webcamStart: "Start Capture",
  webcamStop: "Stop Capture",
  webcamCapture: "Capture",
  webcamDelete: "Delete Picture",
  exwBuyer:
    "Costs and risks from goods availability, export, transit, and import",
  exwSeller: "Costs and risks up to goods availability",
  fcaBuyer: "Costs and risks from the first carrier, transit, and import",
  fcaSeller: "Costs and risks up to the first carrier, export",
  cptBuyer: "Risks from the first carrier, costs to the destination, import",
  cptSeller:
    "Risks up to the first carrier, costs to the destination, export and transit",
  cipBuyer: "Risks from the first carrier, costs to the destination, import",
  cipSeller:
    "Risks up to the first carrier, costs to the destination, export and transit, transport insurance from the first carrier to the destination",
  dapBuyer:
    "Costs and risks from goods ready on the truck at the destination, import",
  dapSeller:
    "Costs and risks up to goods ready on the truck at the destination, export and transit",
  datBuyer:
    "Costs and risks from goods at the terminal or agreed location, import",
  datSeller:
    "Costs and risks up to goods at the terminal or agreed location, export and transit",
  ddpBuyer: "Costs and risks from destination, ready to unload",
  ddpSeller:
    "Costs and risks up to destination, not unloaded, export, import, and transit",
  fasBuyer:
    "Costs and risks from alongside the ship in port, import and transit",
  fasSeller: "Costs and risks up to alongside the ship in port, export",
  fobBuyer:
    "Costs and risks from on board the ship in port, import and transit",
  fobSeller: "Costs and risks up to on board the ship in port, export",
  cfrBuyer:
    "Costs from the destination port, risks from on board the ship, import and transit",
  cfgSeller:
    "Costs up to the destination port, risks up to on board the ship, export",
  cifBuyer:
    "Costs from the destination port, risks from on board the ship, import and transit",
  cifSeller:
    "Costs up to the destination port, risks up to on board the ship, export, sea transport insurance",
  greenExplanationIncoterm:
    "Individual agreements regarding the destination are possible, so the destination can vary from 'on board the ship in the destination port' to 'ready to unload on the truck'.",
  agreedAddress: "Agreed Place",
  redExplanationIncoterm: "Caution! An agreed place was given!",
  destination: "Destination",
  showMore: "Show more",
  titleContains: "Title contains",
  category: "Category",
  markedAsFavorite: "Marked as favorite",
  quickfilterFavorites: "Favorites",
  actionNeeded: "Action needed",
  quickfilterActionNeeded: "Action needed",
  quickfilterPrice: "Price",
  quickFilterFrom: "from",
  quickFilterUpTo: "up to",
  twoFATitle: "Two Factor Authentication",
  twoFAEnabled: "You have enabled two-factor authentication.",
  twoFAEnable: "Enable 2FA",
  twoFADisabled: "You have disabled two-factor authentication.",
  twoFADisable: "Disable 2FA",
  twoFABottomExplanation:
    "Scan this QR code with your authenticator app. If you cannot scan the QR Code, you can manually add this account to your authenticator app using this code:",
  admin: "Admin",
  newemployee: "New Internal Employee",
  accounts: "Accounts",
  redis: "Redis",
  language: "Language",
  companyAdmin: "Admin",
  companySettings: "Company Settings",
  proceedAnyway: "Proceed anyway",
  provideUID: "EU Countries must provide an UID.",
  exactTaxName: "The exact name which is connected to your tax ID.",
  emailToSendOffer: "Enter the E-Mail where you want to send this offer",
  vatRegNoNotValid:
    "There is no valid VAT identification number (UID) linked to your company account. Please ensure that a valid VAT identification number is provided before submitting an offer.",
};
