import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { ChevronDownIcon, FunnelIcon } from "@heroicons/react/20/solid";
import type { TFunction } from "i18next";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router";
import type { ComboBoxSimpleDefinitionType } from "~/components/Comboboxes/ComboBox";
import { isComboBoxSimpleDefinitionType } from "~/components/Comboboxes/ComboBox";
import NumberRangeInput from "~/components/Inputgroups/NumberRange";
import Button from "~/components/buttons/Button";
import ComboBoxOL from "~/components/no conform components/ComboBoxOL";
import { TagType } from "~/constants/prismaEnums";
import type { NumberRange } from "~/types/NumberRange";
import { isMinMaxType } from "~/types/NumberRange";
import { classNames } from "~/utils/misc";

//#region constants
export const SEARCH_PARAM_TRADE_PRODUCT_ID = "tradeproductid";
export const ITEM_COUNT_INTERVAL = 24;
export const MAX_TOPTAGS = 12;

// Search Params
export const SEARCH_PARAM_LIMIT = "limit";
export const SEARCH_PARAM_SORT = "sort";
export const SEARCH_PARAM_TITLE = "title";
export const SEARCH_PARAM_CAT = "cat";
export const SEARCH_PARAM_PRICEMIN = "priceMin";
export const SEARCH_PARAM_PRICEMAX = "priceMax";
export const SEARCH_PARAM_FAV = "fav";
export const SEARCH_PARAM_ACTIONNEEDED = "actionNeeded";
export const SEARCH_PARAM_TAG = "tag_TYPE_KEY";
export const SEARCH_PARAM_TAG_REGEX = /^tag_(.*?)_(.*?)$/;
export const SEARCH_PARAM_NUMBERRANGE_FORMAT = "MIN;MAX";
export const SEARCH_PARAM_NUMBERRANGE_FORMAT_REGEX = /^(\d*);(\d*)$/;
//#endregion constants

//#region types
export enum FilterType {
  Combo,
  NumberRange,
  Checkbox,
  Text,
}

export type Filter<T, U> = {
  label: string;
  key: string;
  type: FilterType;
  inputValue: U;
  currentValue?: T;
};

export enum Sorting {
  Popular,
  Newest,
  LowestPrice,
  HighestPrice,
}

let SortingDisplayName: React.FC<{ sortEnum: Sorting }> = ({ sortEnum }) => {
  let { t } = useTranslation();
  switch (sortEnum) {
    case Sorting.Popular:
      return t("popular");
    case Sorting.Newest:
      return t("newest");
    case Sorting.LowestPrice:
      return t("lowestPrice");
    case Sorting.HighestPrice:
      return t("highestPrice");
    default:
      throw new Error(`Sort type ${sortEnum} not supported`);
  }
};

export type ShopFilters = {
  title: Filter<string, null>;
  category: Filter<
    ComboBoxSimpleDefinitionType,
    ComboBoxSimpleDefinitionType[]
  >;
  price: Filter<NumberRange, null>;
  favorite: Filter<boolean, null>;
  actionNeeded: Filter<boolean, null>;
  tags: Filter<string | NumberRange, null>[];
  sort: Sorting;
};

interface ShopHeaderProps {
  filters: ShopFilters;
  saveNewQuickFilter?: (url: string, title: string | undefined) => void;
  useSort?: boolean;
  showUtpFilters?: boolean;
}
//#endregion types

//#region functions
export function shopFiltersToSearchParams(filters: ShopFilters) {
  let params = new URLSearchParams();
  // check undefined and null separatly here as "if (filters.sort)" fails on enum value 0!
  if (filters.sort !== undefined && filters.sort !== null) {
    params.set(SEARCH_PARAM_SORT, Sorting[filters.sort]);
  }
  if (filters.title.currentValue) {
    params.set(SEARCH_PARAM_TITLE, filters.title.currentValue);
  }
  if (filters.category.currentValue?.key) {
    params.set(SEARCH_PARAM_CAT, filters.category.currentValue.key);
  }
  if (isMinMaxType(filters.price.currentValue)) {
    if (filters.price.currentValue.min) {
      params.set(
        SEARCH_PARAM_PRICEMIN,
        filters.price.currentValue.min.toString()
      );
    }
    if (filters.price.currentValue.max) {
      params.set(
        SEARCH_PARAM_PRICEMAX,
        filters.price.currentValue.max.toString()
      );
    }
  }
  if (filters.favorite.currentValue === true) {
    params.set(SEARCH_PARAM_FAV, "true");
  }
  if (filters.actionNeeded.currentValue === true) {
    params.set(SEARCH_PARAM_ACTIONNEEDED, "true");
  }

  filters.tags.forEach((t) => {
    if (t.currentValue) {
      let searchParamTag = SEARCH_PARAM_TAG.replace(
        "TYPE",
        FilterType[t.type] // ENUM[ENUM.EnumValue] returns the enum key instead of its value
      ).replace("KEY", t.key);
      if (typeof t.currentValue === "string") {
        params.set(searchParamTag, t.currentValue);
      } else if (isMinMaxType(t.currentValue)) {
        if (t.currentValue.min || t.currentValue.max) {
          let rangeParam = SEARCH_PARAM_NUMBERRANGE_FORMAT.replace(
            "MIN",
            (t.currentValue.min ?? "").toString()
          ).replace("MAX", (t.currentValue.max ?? "").toString());
          params.set(searchParamTag, rangeParam);
        }
      } else {
        throw new Error(`type ${t.type} not supported`);
      }
    }
  });
  return params;
}

export function getDefaultFilterName(
  filters: ShopFilters,
  t: TFunction<"translation", undefined>
) {
  let returnValue: string[] = [];
  if (filters.category.currentValue?.value) {
    returnValue.push(filters.category.currentValue.value);
  }
  if (filters.favorite.currentValue === true) {
    returnValue.push(t("quickfilterFavorites"));
  }
  if (filters.actionNeeded.currentValue === true) {
    returnValue.push(t("quickfilterActionNeeded"));
  }
  if (filters.title.currentValue) {
    returnValue.push(filters.title.currentValue);
  }
  let priceString = minMaxToString(
    filters.price.currentValue,
    t("quickfilterPrice"),
    t
  );
  if (priceString) {
    returnValue.push(priceString);
  }
  // if too short, add tags
  if (returnValue.length <= 1) {
    filters.tags.slice(0, 2).forEach((tag) => {
      switch (tag.type) {
        case FilterType.Text:
          if (tag.currentValue) {
            returnValue.push(`${tag.label} ${tag.currentValue}`);
          }
          break;
        case FilterType.NumberRange:
          let tagVal = minMaxToString(tag.currentValue, tag.label, t);
          if (tagVal) {
            returnValue.push(tagVal);
          }
          break;
        default:
          throw new Error(`FilterType ${tag.type} not supported`);
      }
    });
  }
  return returnValue.join(" ");
}

function minMaxToString(
  range: NumberRange | string | undefined,
  title: string,
  t: TFunction<"translation", undefined>
): string | undefined {
  if (isMinMaxType(range)) {
    let val = range;
    if (val.min) {
      if (val.max) {
        return `${title} ${val.min}-${val.max}`;
      } else {
        return `${title} ${t("quickFilterFrom")} ${val.min}`;
      }
    } else if (val.max) {
      return `${title} ${t("quickFilterUpTo")} ${val.max}`;
    }
  }
  return undefined;
}

export function parseDefaultSearchParams(searchParams: URLSearchParams) {
  let sort =
    Sorting[searchParams.get(SEARCH_PARAM_SORT) as keyof typeof Sorting] ??
    Sorting.Popular; // use "Popular" as default filter
  let title = searchParams.get(SEARCH_PARAM_TITLE) ?? undefined;
  let catID = searchParams.get(SEARCH_PARAM_CAT);
  let priceMinString = searchParams.get(SEARCH_PARAM_PRICEMIN) ?? undefined;
  let priceMin = priceMinString ? +priceMinString : undefined;
  let priceMaxString = searchParams.get(SEARCH_PARAM_PRICEMAX) ?? undefined;
  let priceMax = priceMaxString ? +priceMaxString : undefined;
  let fav = searchParams.has(SEARCH_PARAM_FAV) ? true : undefined;
  let actionNeeded = searchParams.has(SEARCH_PARAM_ACTIONNEEDED)
    ? true
    : undefined;
  return { sort, title, catID, priceMin, priceMax, fav, actionNeeded };
}

export function convertTagTypeToFilterType(tagType: TagType) {
  switch (tagType) {
    case TagType.String:
      return FilterType.Text;
    case TagType.Number:
      return FilterType.NumberRange;
    default:
      throw new Error(`type ${tagType} not supported!`);
  }
}
//#endregion function

export default function ShopHeader(props: ShopHeaderProps) {
  let [, setSearchParams] = useSearchParams();
  let [currentFilterCount, setCurrentFilterCount] = useState<number>(0);
  let [filters, setFilters] = useState(props.filters);
  let { t } = useTranslation();

  let useSort = props.useSort ?? true;

  // this is needed to update tag filters when the properties get changed in the parent control.
  // usually, this happens automatically, but because we handle the filters as state here, this extra step is required to overwrite our current state.
  useEffect(() => {
    let updatedFilter = { ...filters };
    updatedFilter = props.filters;
    setFilters(updatedFilter);
  }, [filters, props]);

  useEffect(() => {
    let count =
      (filters.category.currentValue ? 1 : 0) +
      (filters.price.currentValue &&
      isMinMaxType(filters.price.currentValue) &&
      (filters.price.currentValue.min || filters.price.currentValue.max)
        ? 1
        : 0) +
      (filters.favorite.currentValue ? 1 : 0) +
      (filters.actionNeeded.currentValue ? 1 : 0) +
      (filters.title.currentValue ? 1 : 0) +
      filters.tags.filter((x) => {
        if (isMinMaxType(x.currentValue)) {
          return x.currentValue.min || x.currentValue.max;
        }
        return x.currentValue;
      }).length;
    setCurrentFilterCount(count);
  }, [filters]);

  let handleFilterChange = (index: number, value: any) => {
    let updatedFilter = { ...filters };
    updatedFilter.tags[index].currentValue = value;
    setFilters(updatedFilter);
  };
  let handleTitleChange = (index: number, value: any) => {
    let updatedFilter = { ...filters };
    updatedFilter.title.currentValue = value;
    setFilters(updatedFilter);
  };
  let handleCategoryChange = (index: number, value: any) => {
    let updatedFilter = { ...filters };
    updatedFilter.category.currentValue = value;
    setFilters(updatedFilter);
  };
  let handlePriceChange = (index: number, value: any) => {
    if (
      !filters.price.currentValue ||
      (isMinMaxType(filters.price.currentValue) &&
        (filters.price.currentValue?.min !== value?.min ||
          filters.price.currentValue?.max !== value?.max))
    ) {
      let updatedFilter = { ...filters };
      updatedFilter.price.currentValue = value;
      setFilters(updatedFilter);
    }
  };
  let handleFavoriteChange = (index: number, value: any) => {
    let updatedFilter = { ...filters };
    updatedFilter.favorite.currentValue = value;
    setFilters(updatedFilter);
  };

  let handleActionNeededChange = (index: number, value: any) => {
    let updatedFilter = { ...filters };
    updatedFilter.actionNeeded.currentValue = value;
    setFilters(updatedFilter);
  };

  let handleSortChange = (value: Sorting) => {
    let updatedFilter = { ...filters };
    updatedFilter.sort = value;
    setFilters(updatedFilter);
    doFilter(updatedFilter);
  };

  let clearAllFilters = () => {
    let updatedFilter = { ...filters };
    updatedFilter.title.currentValue = undefined;
    updatedFilter.category.currentValue = undefined;
    if (isMinMaxType(updatedFilter.price.currentValue)) {
      updatedFilter.price.currentValue.min = undefined;
      updatedFilter.price.currentValue.max = undefined;
    }
    updatedFilter.favorite.currentValue = undefined;
    updatedFilter.actionNeeded.currentValue = undefined;
    updatedFilter.tags.forEach((tag) => (tag.currentValue = undefined));
    setFilters(updatedFilter);
    doFilter();
  };

  let doFilter = (customFilter?: ShopFilters) => {
    let params = shopFiltersToSearchParams(customFilter ?? filters);
    setSearchParams(params, {
      preventScrollReset: true,
    });
  };

  let addQuickFilter = () => {
    if (props.saveNewQuickFilter) {
      let params = shopFiltersToSearchParams(filters);
      let title = getDefaultFilterName(filters, t);
      props.saveNewQuickFilter(params.toString(), title);
    }
  };

  return (
    <>
      <Disclosure
        as="section"
        aria-labelledby="filter-heading"
        className="grid items-center border-b border-t border-gray-light dark:border-gray-dark"
      >
        {(panel) => {
          let { open, close } = panel;
          return (
            <>
              <h2 id="filter-heading" className="sr-only">
                {t("filters")}
              </h2>
              <div className="relative col-start-1 row-start-1 py-4">
                <div className="mx-auto flex max-w-7xl space-x-6 divide-x divide-gray-light dark:divide-gray-dark px-4 text-sm sm:px-6 lg:px-8">
                  <div>
                    <DisclosureButton className="group flex items-center font-medium text-gray-light dark:text-gray-dark hover:text-gray-dark hover:dark:text-gray-light">
                      <FunnelIcon
                        className="mr-2 h-5 w-5 flex-none text-gray-light dark:text-gray-dark group-hover:text-gray-dark group-hover:dark:text-gray-light"
                        aria-hidden="true"
                      />
                      {currentFilterCount}{" "}
                      {currentFilterCount === 1 ? t("filter") : t("filters")}
                    </DisclosureButton>
                  </div>
                  <div className="pl-6">
                    <button
                      type="button"
                      className="text-gray-light dark:text-gray-dark hover:text-gray-dark hover:dark:text-gray-light"
                      onClick={() => {
                        if (open) {
                          close();
                        }
                        clearAllFilters();
                      }}
                    >
                      {t("clearAll")}
                    </button>
                  </div>
                </div>
              </div>
              <DisclosurePanel className="border-t border-gray-light dark:border-gray-dark py-10 text-black dark:text-white">
                <div className="mx-auto grid max-w-7xl grid-cols-2 gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
                  <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
                    <fieldset>
                      <legend className="block font-medium">
                        {t("filter")}
                      </legend>
                      <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                        {getFilterControl(
                          filters.title,
                          0,
                          "title",
                          handleTitleChange
                        )}
                        {getFilterControl(
                          filters.category,
                          0,
                          "category",
                          handleCategoryChange
                        )}
                        {getFilterControl(
                          filters.price,
                          0,
                          "price",
                          handlePriceChange
                        )}
                        {getFilterControl(
                          filters.favorite,
                          0,
                          "favorite",
                          handleFavoriteChange
                        )}
                        {props.showUtpFilters === true &&
                          getFilterControl(
                            filters.actionNeeded,
                            0,
                            "actionNeeded",
                            handleActionNeededChange
                          )}
                      </div>
                    </fieldset>
                    <fieldset>
                      <legend className="block font-medium">
                        {t("productProperties")}
                      </legend>
                      <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                        {filters.tags
                          .slice(0, 4)
                          .map((option, optionIdx) =>
                            getFilterControl(
                              option,
                              optionIdx,
                              "tag",
                              handleFilterChange
                            )
                          )}
                      </div>
                    </fieldset>
                  </div>
                  <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
                    <fieldset>
                      <div className="space-y-6 pt-6 mt-5 sm:space-y-4 sm:pt-4">
                        {filters.tags.slice(4, 8).map((option, optionIdx) =>
                          getFilterControl(
                            option,
                            optionIdx + 4, // +4 because of slicing
                            "tag",
                            handleFilterChange
                          )
                        )}
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className="space-y-6 pt-6 mt-5 sm:space-y-4 sm:pt-4">
                        {filters.tags.slice(8, 12).map((option, optionIdx) =>
                          getFilterControl(
                            option,
                            optionIdx + 8, // +8 because of slicing
                            "tag",
                            handleFilterChange
                          )
                        )}
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="flex items-center justify-between text-center mt-2">
                  <Button
                    additionalClassNames="mx-auto"
                    onClick={() => doFilter()}
                  >
                    {t("filter")}
                  </Button>
                  {props.saveNewQuickFilter && (
                    <Button
                      onClick={() => addQuickFilter()}
                      aria-label="addQuickFilter"
                    >
                      +
                    </Button>
                  )}
                </div>
              </DisclosurePanel>
              {useSort === true && (
                <div className="col-start-1 row-start-1 py-4">
                  <div className="mx-auto flex max-w-7xl justify-end px-4 sm:px-6 lg:px-8">
                    <Menu as="div" className="relative inline-block">
                      <div className="flex">
                        <MenuButton className="group inline-flex justify-center text-sm font-medium text-gray-light dark:text-gray-dark hover:text-gray-dark hover:dark:text-gray-light">
                          {t("sort")}
                          <ChevronDownIcon
                            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-light dark:text-gray-dark group-hover:text-gray-dark group-hover:dark:text-gray-light"
                            aria-hidden="true"
                          />
                        </MenuButton>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <MenuItems className="absolute right-0 z-50 mt-2 w-40 origin-top-right rounded-md bg-bg-light dark:bg-bg-dark shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            {Object.values(Sorting)
                              .filter((item) => {
                                return isNaN(Number(item));
                              })
                              .map(
                                (key) => Sorting[key as keyof typeof Sorting]
                              )
                              .map((option) => (
                                <MenuItem key={option}>
                                  {({ active }) => (
                                    <button
                                      className={classNames(
                                        option === filters.sort
                                          ? "font-medium text-black dark:text-text-dark"
                                          : "text-gray-light dark:text-gray-dark",
                                        active
                                          ? "bg-gray-dark dark:bg-gray-light"
                                          : "",
                                        "block px-4 py-2 text-sm"
                                      )}
                                      onClick={(e) => {
                                        handleSortChange(option);
                                      }}
                                    >
                                      <SortingDisplayName sortEnum={option} />
                                    </button>
                                  )}
                                </MenuItem>
                              ))}
                          </div>
                        </MenuItems>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              )}
            </>
          );
        }}
      </Disclosure>
    </>
  );
}

function getFilterControl(
  option: Filter<any, any>,
  optionIdx: number,
  controlName: string,
  handleFilterChange: (index: number, value: any) => void
) {
  optionIdx = optionIdx ?? 0;
  let controlId = `${controlName}-${optionIdx}`;
  return (
    <div key={option.label} className="flex items-center text-base sm:text-sm">
      {option.type === FilterType.Checkbox && (
        <>
          <input
            id={controlId}
            name={controlName}
            type="checkbox"
            className="h-4 w-4 flex-shrink-0 rounded border-gray-light dark:border-gray-dark text-button-light dark:text-button-dark focus:ring-link-light focus:dark:ring-link-dark"
            checked={
              (typeof option.currentValue === "boolean" &&
                option.currentValue) ||
              false
            }
            onChange={(e) => handleFilterChange(optionIdx, e.target.checked)}
          />
          <label
            htmlFor={controlId}
            className="ml-3 min-w-0 flex-1 text-gray-light dark:text-gray-dark"
          >
            {option.label}
          </label>
        </>
      )}
      {option.type === FilterType.Text && (
        <div className="flex items-center gap-x-4">
          <label
            htmlFor={controlId}
            className="text-gray-light dark:text-gray-dark flex-shrink-0"
          >
            {option.label}
          </label>
          <input
            id={controlId}
            name={controlName}
            aria-label={option.label}
            value={
              typeof option.currentValue === "string" ? option.currentValue : ""
            }
            className="w-full bg-bg-light dark:bg-bg-dark rounded-md border-0 py-1.5 text-black dark:text-white ring-1 ring-inset ring-link-light dark:ring-link-dark 
        placeholder:text-gray-placeholder focus:ring-2 focus:ring-inset focus:ring-button-light focus:dark:ring-button-dark sm:text-sm sm:leading-6"
            onChange={(e) => handleFilterChange(optionIdx, e.target.value)}
          ></input>
        </div>
      )}
      {option.type === FilterType.NumberRange && (
        <NumberRangeInput
          value={
            isMinMaxType(option.currentValue) ? option.currentValue : undefined
          }
          onChange={(e) => handleFilterChange(optionIdx, e)}
          assignedToLabel={option.label}
          labelClassName="text-gray-placeholder"
        ></NumberRangeInput>
      )}
      {option.type === FilterType.Combo && option.inputValue && (
        <>
          <ComboBoxOL
            name={controlName}
            comboBoxData={option.inputValue}
            assignedToLabel={option.label}
            labelClassName="text-gray-placeholder"
            value={
              isComboBoxSimpleDefinitionType(option.currentValue)
                ? option.currentValue?.value
                : null
            }
            onChangeCustom={(e: ComboBoxSimpleDefinitionType) =>
              handleFilterChange(optionIdx, e)
            }
          />
        </>
      )}
    </div>
  );
}
