import type { FieldMetadata } from "@conform-to/react";
import { getInputProps } from "@conform-to/react";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useEffect, useId, useState } from "react";
import { useTranslation } from "react-i18next";
import type { ListOfErrors } from "../Inputgroups/Form";
import { ErrorList } from "../Inputgroups/Form";
import { LabelOLnew } from "../Inputgroups/Label";
import { requiredErrorLogic } from "../Inputgroups/utils";
import { ProgressiveClientOnly } from "../ProgressiveClientOnly";

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(" ");
}

export type ComboBoxSimpleDefinitionType = {
  key: string;
  value: string;
};

export function isComboBoxSimpleDefinitionType(
  value: any
): value is ComboBoxSimpleDefinitionType {
  return (
    typeof value === "object" &&
    value !== null &&
    "key" in value &&
    "value" in value
  );
}

type ComboBoxProps = {
  fieldschema: FieldMetadata;
  options: Array<ComboBoxSimpleDefinitionType>;
  label: string;
  onChangeCustom?: (
    selectedOption: ComboBoxSimpleDefinitionType | null
  ) => void;
  errors?: ListOfErrors;
  disabled?: boolean;
};

export let ComboBox = ({
  options,
  label,
  fieldschema,
  onChangeCustom,
  errors,
  disabled = false,
}: ComboBoxProps): JSX.Element => {
  let inputProps = {
    ...getInputProps(fieldschema, {
      type: "hidden",
    }),
  };
  let fallbackId = useId();
  let id = inputProps.id ?? fallbackId;
  let { tempErrorList, isRequiredError, errorId } = requiredErrorLogic(
    errors,
    id
  );

  let [query, setQuery] = useState("");

  let filteredOptions: ComboBoxSimpleDefinitionType[] =
    query === ""
      ? options
      : options.filter((option) => {
          return option.value.toLowerCase().includes(query.toLowerCase());
        });

  let [selectedOption, setSelectedOption] =
    useState<ComboBoxSimpleDefinitionType | null>(null);

  let { t } = useTranslation();

  useEffect(() => {
    let selected = filteredOptions.filter(
      (opt) => opt.key === inputProps.defaultValue
    );
    if (selected.length > 0) {
      setSelectedOption(selected[0]);
    }
  }, [filteredOptions, inputProps.defaultValue]);

  return (
    <ProgressiveClientOnly className="animate-fade">
      <Combobox
        as="div"
        value={selectedOption}
        onChange={(opt) => {
          setSelectedOption(opt);
          onChangeCustom && onChangeCustom(opt);
        }}
        disabled={disabled}
      >
        {/* needed for zod to retrieve input data */}
        <input
          {...inputProps}
          defaultValue={selectedOption?.key ?? inputProps.defaultValue}
        />{" "}
        <div className="relative mt-0 mb-3 rounded-md">
          <label className="block text-sm font-medium leading-6 text-black dark:text-white">
            {label} {inputProps.required && "*"}
            <ComboboxInput
              className={`w-full mt-1 rounded-md border-0 bg-bg-light dark:bg-bg-dark py-1.5 pl-3 pr-10 
              text-text-light dark:text-text-dark ${
                isRequiredError
                  ? "ring-2 shadow-lg shadow-red-600/50 ring-red-600 dark:ring-red-600"
                  : "ring-1 shadow-sm  ring-button-light dark:ring-button-dark "
              } ring-inset focus:ring-link-light focus:dark:ring-link-dark 
              focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 
              read-only:text-gray-400 read-only:ring-gray-500
              dark:read-only:text-gray-400 dark:read-only:ring-gray-500`}
              onChange={(event) => {
                setQuery(event.target.value);
              }}
              displayValue={(option: ComboBoxSimpleDefinitionType | null) =>
                option ? t(option.value) : ""
              }
              placeholder={t("pleaseSelectAValue")}
              autoComplete="off"
              readOnly={disabled}
            />
            <ComboboxButton className="absolute mt-6 inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </ComboboxButton>
          </label>
          {filteredOptions.length > 0 && (
            <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-bg-light dark:bg-bg-dark py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredOptions.map((option) => (
                <ComboboxOption
                  key={option.key}
                  value={option}
                  className={({ focus }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      focus
                        ? "bg-button-light dark:bg-button-dark text-black dark:text-white"
                        : "text-black dark:text-text-dark"
                    )
                  }
                >
                  {({ focus, selected }) => (
                    <>
                      <span
                        className={classNames(
                          "block truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {t(option.value)}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            focus
                              ? "text-black dark:text-white"
                              : "text-button-light dark:bg-button-dark"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </ComboboxOption>
              ))}
            </ComboboxOptions>
          )}
        </div>
        {errorId ? (
          <div className="min-h-[32px] px-4 pb-3 pt-1">
            <ErrorList id={errorId} errors={tempErrorList} />
          </div>
        ) : null}
      </Combobox>
    </ProgressiveClientOnly>
  );
};

export let ComboBoxOLnew = ({
  options,
  label,
  fieldschema,
  onChangeCustom,
  errors,
}: {
  fieldschema: FieldMetadata;
  options: Array<ComboBoxSimpleDefinitionType>;
  label: string;
  onChangeCustom?: (
    selectedOption: ComboBoxSimpleDefinitionType | null
  ) => void;
  errors?: ListOfErrors;
}): JSX.Element => {
  let [query, setQuery] = useState("");
  let { t } = useTranslation();
  let filteredOptions =
    query === ""
      ? options
      : options.filter((option) => {
          return option.value.toLowerCase().includes(query.toLowerCase());
        });
  let [selectedOption, setSelectedOption] =
    useState<ComboBoxSimpleDefinitionType | null>(null);
  let inputProps = {
    ...getInputProps(fieldschema, {
      type: "hidden",
    }),
    // "aria-label": "loadingaddress",
  };
  let fallbackId = useId();
  let id = inputProps.id ?? fallbackId;
  let { tempErrorList, isRequiredError, errorId } = requiredErrorLogic(
    errors,
    id
  );

  useEffect(() => {
    let selected = filteredOptions.filter(
      (opt) => opt.key === inputProps.defaultValue
    );
    if (selected.length > 0) {
      setSelectedOption(selected[0]);
    }
  }, [filteredOptions, inputProps.defaultValue]);

  return (
    <ProgressiveClientOnly className="animate-fade">
      <Combobox
        as="div"
        value={selectedOption}
        onChange={(opt) => {
          setSelectedOption(opt);
          onChangeCustom && onChangeCustom(opt);
        }}
      >
        {/* needed for zod to retrieve input data */}
        <input
          {...inputProps}
          defaultValue={selectedOption?.key ?? inputProps.defaultValue}
        />{" "}
        <div className="relative mt-1">
          <div className="flex flex-row justify-items-center items-center">
            <LabelOLnew is_required={inputProps.required}>{label}</LabelOLnew>
            <div className="w-full">
              <ComboboxInput
                className={`w-full mt-[-4px] rounded-md border-0 bg-bg-light dark:bg-bg-dark pl-3 pr-10 text-black dark:text-text-dark 
               ${
                 isRequiredError
                   ? "ring-2 shadow-lg shadow-red-600/50 ring-red-600 dark:ring-red-600"
                   : "ring-1 shadow-sm  ring-button-light dark:ring-button-dark "
               } ring-inset focus:ring-link-light focus:dark:ring-link-dark focus:ring-2 focus:ring-inset 
               sm:text-sm sm:leading-6`}
                onChange={(event) => {
                  setQuery(event.target.value);
                }}
                displayValue={(option: ComboBoxSimpleDefinitionType | null) =>
                  option ? t(option.value) : ""
                }
                placeholder={t("pleaseSelectAValue")}
                autoComplete="off"
              />
              <ComboboxButton className="absolute mt-[-4px] inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </ComboboxButton>
              {filteredOptions.length > 0 && (
                <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-auto overflow-auto rounded-md bg-bg-light dark:bg-bg-dark py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filteredOptions.map((option) => (
                    <ComboboxOption
                      key={option.key}
                      value={option}
                      className={({ focus }) =>
                        classNames(
                          "relative cursor-default select-none py-2 pl-3 pr-9",
                          focus
                            ? "bg-button-light dark:bg-button-dark text-black dark:text-white"
                            : "text-black dark:text-text-dark"
                        )
                      }
                    >
                      {({ focus, selected }) => (
                        <>
                          <span
                            className={classNames(
                              "block truncate",
                              selected && "font-semibold"
                            )}
                          >
                            {t(option.value)}
                          </span>

                          {selected && (
                            <span
                              className={classNames(
                                "absolute inset-y-0 right-0 flex items-center pr-4",
                                focus
                                  ? "text-black dark:text-white"
                                  : "text-button-light dark:bg-button-dark"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </>
                      )}
                    </ComboboxOption>
                  ))}
                </ComboboxOptions>
              )}{" "}
            </div>
          </div>
        </div>
        {errorId ? (
          <div className="min-h-[32px] px-4 pb-3 pt-1">
            <ErrorList id={errorId} errors={tempErrorList} />
          </div>
        ) : null}
      </Combobox>
    </ProgressiveClientOnly>
  );
};
