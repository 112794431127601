import type { Route } from ".react-router/types/app/routes/download.dmsfile.$dmsfileid/+types/route";
import { isUserAlreadyLoggedIn } from "~/services/auth.server";
import { prisma } from "~/services/db.server";
import { getDownloadDocumentFromDms } from "~/services/dms.server";

export const ROUTE_FILEDOWNLOAD_BYDMSFILEID = "/download/dmsfile/";
export const loader = async ({ params, request }: Route.LoaderArgs) => {
  await isUserAlreadyLoggedIn(request);
  if (params.dmsfileid) {
    let teedyFile = await prisma.dms_file.findUniqueOrThrow({
      where: {
        id: params.dmsfileid,
      },
    });
    return await getDownloadDocumentFromDms(
      teedyFile.file_id,
      teedyFile.file_name
    );
  }
};
